<template>
  <div class="section-blue">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="question">¿por que nos eligen?</h2>
          <h3 class="title">
            Aprendizaje Garantizado
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3" v-for="(learning, index) in learnings" :key="index">
          <div class="item-learning">
            <div class="icon">
              <img :src="require(`@/assets/img/${learning.icon}`)" alt="line" />
            </div>
            <img src="@/assets/img/line.svg" alt="line" class="line" />
            <h4 class="learnig-title">{{ learning.title  }}</h4>
            <p class="learning-text">{{ learning.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
export default {
  data(){
    return {
      learnings: []
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}aprendizajeData.json`)
        this.learnings = data;
      } catch (error) {
        console.log(error)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

  @media (max-width: 768px) {
    .col-6{
      padding-right: 12px !important;
      padding-left: 12px !important;
    }
  }

  .section-blue{
    background-color: #003359;
    padding: 40px 0px 100px 0px;

    color: #fff;
    .question{
      font-family:  'Avenir LT Pro Light';
      font-size: 16px;
      font-size: 20px;
      line-height: 140%;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      @media (max-width: 768px) {
        line-height: 20px;
      }
    }
    .title{
      font-family: 'Avenir LT Pro Black';
      font-weight: 750;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 120%;
      }
    }

    .item-learning{
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon{
        width: 47px;
        margin-bottom: 10px;
        img{
          width: 100%;
        }
      }
      .line{
        width: 32px;
        margin-bottom: 10px;
      }
      .learnig-title{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;
        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 120%;
        }
      }
      .learning-text{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }

  }
</style>