<template>
    <layout-default>
      <div class="politicas wse-page">
        <div class="banner">
  
        </div>
        <div class="content-garantee mb-5">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h1 class="title-garantee mt-5">
                  Retracto
                </h1>
                <h2 class="subtitle-garantee"> NO APLICACIÓN DEL DERECHO A RETRACTO </h2>
                <p class="text-garantee">
                    Conforme con el artículo 3 bis inciso 2º, Wall Street English informa que respecto de los servicios que presta, contratados por el alumnos y/o apoderado, no aplica el derecho a retracto establecido en el artículo 3 bis inciso 1º, ambas disposiciones de la Ley 19.496 sobre Protección de los Derechos de los Consumidores. 
                </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </layout-default>
  </template>
  
  <script>
  import LayoutDefault from "@/layouts/Default";
  export default {
    components:{
      LayoutDefault
    },
     metaInfo: {
      title: 'Wall Street English',
      titleTemplate: '%s | Política de Privacidad y Confidencialidad'
    },
  }
  </script>
  
  <style lang="scss" scoped>
  
  .title-garantee{
    font-family: 'Avenir LT Pro Black';
    color: #003359;
    font-size: 36px;
    line-height: 150%;
    font-weight: 700;
    margin-bottom: 15px;
    opacity: 0.8;
  }
  
  .subtitle-garantee{
    font-family: 'Avenir LT Pro Black';
    font-style: normal;
    font-weight: 750;
    font-size: 24px;
    line-height: 140%;
    color: #003359;
    opacity: 0.8;
  }
  
  p, li{
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #003359;
  }
  
  ol {
    list-style-type: upper-alpha;
  }
  
  </style>