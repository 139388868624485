import Vue from 'vue'
import Axios from 'axios'
import App from './App.vue'
import router from './router'
import VueCarousel from 'vue-carousel'
import VueScreen from 'vue-screen';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import VueRouter from 'vue-router'
import VueTheMask from 'vue-the-mask';
import store from './store/index';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vuelidate from 'vuelidate'
import moment from 'moment'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import VueMeta from 'vue-meta'
import vClickOutside from "v-click-outside"

import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

Vue.use(VueRouter)
Vue.use(VueCarousel);
Vue.config.productionTip = false
Vue.use(VueScreen,'bootstrap');
Vue.use(BootstrapVue);
Vue.use(VueTheMask);
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.use(vClickOutside)


global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
  loaderOptions: {
    useRecaptchaNet: true
  }
})

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
});

Vue.prototype.$http = axios;
Vue.prototype.Axios = axios;

/*require('jquery-validation');
require('select2');

Vue.directive('select2', {
  inserted(el) {
    $(el).on('select2:select', () => {
      const event = new Event('change', { bubbles: true, cancelable: true });
      el.dispatchEvent(event);
    });

    $(el).on('select2:unselect', () => {
      const event = new Event('change', { bubbles: true, cancelable: true })
      el.dispatchEvent(event)
    })
  },
});*/

Vue.filter('moneyFormatter', function (value, locale, currency, symbol) {
  if (!locale) {
    value = Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP", minimumFractionDigits: 0 }).format(value).replace('$', '$ ');
  } else {
    value = Intl.NumberFormat(locale, { style: "currency", currency: currency, minimumFractionDigits: 0 }).format(value).replace(symbol, symbol + ' ');
  }
  return value;
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
})

Vue.filter('formatDateHour', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss')
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
