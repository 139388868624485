<template>
  <div class="mobile-modality" id="plansM" >
    <div class="container">
      <h3 class="title">¿Qué modalidad te acomoda?</h3>
      <div class="card item-modality" v-for="(modality, index) in modalitys" :key="index" >
        <div class="card-body">

          <div class="modaly-header">
            <div class="modaly-avatar">
              <img :src="require(`@/assets/img/${modality.image}`)" :alt="modality.title" />
            </div>
            <div class="modaly-title-content">
              <h3 class="modaly-title">{{ modality.title }}</h3>
            </div>
          </div>
          <div class="modaly-content">
            <div class="modaly-content-data" v-for="(featured, k) in modality.featureds" :key="k" >
              <div class="icon">
                <img :src="require(`@/assets/img/${featured.icon}`)"  :alt="featured.icon" />
              </div>
              <div class="text">
                <p>{{ featured.text }}</p>
              </div>
            </div>
            <div class="flex justifi-content-start w-100">
              <span v-if="modality.alert" class="text-error mb-2">
                {{modality.alert}}
              </span>
            </div>
            <div class="button_slide" :class="modality.colorName" @click="viewPlan(modality.slug)"
              :style="{ color: modality.color, borderColor: modality.color }"
            >
              Ver planes
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return {
        modalitys: []
      }
    },
    mounted(){
      this.getData()
    },
    methods:{
      async getData(){
        try {
          const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}modalityData.json`)
          this.modalitys = data;
        } catch (error) {
          console.log(error)
        }
      },
      appllyStyles(color){
        return {
          color: color, 
          borderColor: color
        }
      },
      viewPlan(slug){
        this.$emit('selectedPlan', slug)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mobile-modality{
    padding-top: 20px;
    background-color: #F9F9F9;
    @media (max-width: 768px){
      padding-bottom: 44px;
    }
    .title{
      font-family: 'Avenir LT Pro Black';
      color: #003359;
      font-size: 24px !important;
      line-height: 120% !important;
      font-weight: bold;
      text-align: center;
      margin: 30px 0 50px 0;
    }

    .item-modality{
      margin-bottom: 15px;
      background: #FFFFFF;
      border: 1px solid #ECECEC !important;
      box-sizing: border-box !important;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
      border-radius: 8px !important;
      @media (max-width: 768px){
        margin-bottom: 24px;
      }
    }

    .modaly-header{
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .modaly-avatar{
        width: 50px;
        margin: 0 15px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .modaly-title-content{
        flex: 1;
        .modaly-title{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 20px;
          line-height: 140%;
          display: flex;
          align-items: flex-end;
          text-transform: uppercase;
          color: #404040;
          @media (max-width: 768px) {
          margin-bottom: 0;
          }
        }
      }
    }

    .modaly-content{
      display: flex;
      flex-direction: column;
      .modaly-content-data{
        display: flex;
        .icon{
          width: 24px;
          margin: 0 15px;
        }
        .text{
          p{
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 140%;
            display: flex;
            align-items: flex-end;
            color: #404040;
          }
        }
      }

      .button_slide {
        margin-top: 20px;
        border: 1px solid;
        border-radius: 4px;
        padding: auto;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: 750;
        font-size: 20px;
        line-height: 140%;
        cursor: pointer;
        -webkit-transition: ease-out 0.4s;
        -moz-transition: ease-out 0.4s;
        transition: ease-out 0.4s;
      }

      .slide_right_azul{
        background: #0082A9;
        background: linear-gradient(to left, transparent 50%, #0082A9 50%) right;
        background-size: 200%;
        &:hover{
          color: #fff !important;
          background-position: left;
        }
      }
      .slide_right_rojo{
        background: #F5616E;
        background: linear-gradient(to left, transparent 50%, #F5616E 50%) right;
        background-size: 200%;
        &:hover{
          color: #fff !important;
          background-position: left;
        }
      }
      .slide_right_turquesa{
        background: #64CFE9;
        background: linear-gradient(to left, transparent 50%, #64CFE9 50%) right;
        background-size: 200%;
        &:hover{
          color: #fff !important;
          background-position: left;
        }
      }
      .slide_right_salmon{
        background: #FF8E7E;
        background: linear-gradient(to left, transparent 50%, #FF8E7E 50%) right;
        background-size: 200%;
        &:hover{
          color: #fff !important;
          background-position: left;
        }
      }
    }
  }

</style>