<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss">
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
  * {
    box-sizing: border-box;
  }

  .wse-page{
    padding-top: 70px;
    @media (max-width: 768px) {
      padding-top: 60px;
    }
  }
  @import "assets/scss/main";
</style>

