<template>
    <layout-default>
      <div class="politicas wse-page">
        <div class="banner">
  
        </div>
        <div class="content-garantee mb-5">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h1 class="title-garantee mt-5">
                  Información del vendedor:
                </h1>
                <p class="text-garantee">
                    Wall Street English es una persona jurídica cuya razón social es Centro de Estudios Tecnológicos de Inglés Ltda., Rol Único Tributario N° 78.952.330-4, domiciliada en Avenida Holanda N° 100, Oficina 506, comuna de Providencia, Región Metropolitana, y representada legalmente por José Ramón Valenzuela Álvarez-Salamanca, cédula de identidad N° 9.418.195-k, del mismo domicilio.
                </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </layout-default>
  </template>
  
  <script>
  import LayoutDefault from "@/layouts/Default";
  export default {
    components:{
      LayoutDefault
    },
     metaInfo: {
      title: 'Wall Street English',
      titleTemplate: '%s | Política de Privacidad y Confidencialidad'
    },
  }
  </script>
  
  <style lang="scss" scoped>
  
  .title-garantee{
    font-family: 'Avenir LT Pro Black';
    color: #003359;
    font-size: 36px;
    line-height: 150%;
    font-weight: 700;
    margin-bottom: 15px;
    opacity: 0.8;
  }
  
  .subtitle-garantee{
    font-family: 'Avenir LT Pro Black';
    font-style: normal;
    font-weight: 750;
    font-size: 24px;
    line-height: 140%;
    color: #003359;
    opacity: 0.8;
  }
  
  p, li{
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #003359;
  }
  
  ol {
    list-style-type: upper-alpha;
  }
  
  </style>