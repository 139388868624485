<template>
  <div class="qulify-section">
    <div class="container">
      <div class="qulify-content-title">
        <h3>
          Nuestra metodología considera <b>20 niveles</b> que van desde el más básico hasta un nivel avanzado de dominio del idioma
        </h3>
      </div>
      <div class="qulify-content">
        <div class="qulify-item" v-for="(item, index) in items" :key="index" 
          :style="{ backgroundColor: item.color}"
        >
          <div class="qulify-item-numbers">
            <div class="note" v-for="(note, k) in item.notes" :key="k"  
              :style="{ backgroundColor: item.colorNote}"
            >
              {{ note }}
            </div>
          </div>
          <div class="qulify-item-text">
            <span class="qulify-text">Nivel CEFR <b>{{ item.sigle }}</b></span>
            <!--span class="qulify-text"><b>{{ item.text }}</b></span-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      items: []
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}qualifyData.json`)
        this.items = data;
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .qulify-section {
    padding-bottom: 80px;
  } 
  .qulify-content-title{
    padding: 80px 0;
    @media (max-width: 768px) {
      padding: 50px 0 40px 0;
    }
    h3{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 140%;
      text-align: center;
      color: #003359;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 20px; 
        opacity: 0.8;
        text-align: center;
      }
    }
  }
  .qulify-content{
    display: flex;
    padding: 0 25px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .qulify-item{
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin-bottom: 7px;
      }
      flex: 1;
      .qulify-item-numbers{
        display: flex;
        .note{
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          height: 60px;
          border-right: 1px solid #fff;
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 19.016px;
          line-height: 140%;
          color: #fff;
          &:last-child{
            border-right: none;
          }
          @media (max-width: 768px) {
            height: 35.65px;
          }
        }
      }
      .qulify-item-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80px;
        @media (max-width: 768px) {
          flex-direction: row;
          height: 33.28px;
        }
        .qulify-text{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 16.639px;
          line-height: 24px;
          color: #FFFFFF;
        }
      }
    } 
  }
</style>