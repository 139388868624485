import Vue from 'vue'
import VueRouter from 'vue-router';

import Home from "@/views/Home";
import Modalities from "@/views/Modalities";
import Checkout from "@/views/Checkout";
// import Test from "@/views/Test";
import NotFound from "@/views/NotFound";
import PaymentResult from "@/views/PaymentResult";
import Guarantee from "@/views/Guarantee";
import PoliticaDePrivacidadYConfidencialidad from "@/views/PoliticaDePrivacidadYConfidencialidad";
import InformacionDelVendedor from "@/views/InformacionDelVendedor";
import Retracto from "@/views/Retracto";


Vue.use(VueRouter);

const routes = [
    {
        path: '',
        component: Home,
        name: 'Home'
    },
    {
        path: '/modality/:slug',
        component: Modalities,
        name: 'Modality'
    },
    /*{
        path: '/test',
        component: Test,
        name: 'Test'
    },*/
    {
        path: '/checkout',
        component: Checkout,
        name: 'Checkout'
    },
    {
        path: '/payment-result',
        component: PaymentResult,
        name: 'PaymentResult'
    },
    {
        path: '/guarantee',
        component: Guarantee,
        name: 'Guarantee'
    },{
        path: '/politica-de-privacidad-y-confidencialidad',
        component: PoliticaDePrivacidadYConfidencialidad,
        name: 'PoliticaDePrivacidadYConfidencialidad'
    },{
        path: '/informaciondelvendedor',
        component: InformacionDelVendedor,
        name: 'InformacionDelVendedor'
    },
    {
        path: '/retracto',
        component: Retracto,
        name: 'Retracto'
    },
   {
        path: '/404',
        component: NotFound,
        name: '404'
    },
    /* 404 - always has to be the last one*/
    { path: '*', redirect: { name: '404' } },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router; 