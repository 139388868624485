<template>
  <div class="publicy-desktop">
    <div class="container-fluid">
      <div class="row content-title">
        <div class="col-12">
          <h2 class="publicy-desktop-title">
            Aprende con el método <br>Wall Street English
          </h2>
        </div>
      </div>
      <div class="row content">
        <div class="col-md-6">
          <div class="publicy-desktop-image">
            <img src="@/assets/img/publicy.png" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="publicy-desktop-text-content">
            <div class="publicy-desktop-item" v-for="(publicy, index) in publicys" :key="index" >
              <div class="publicy-desktop-item-image">
                <img :src="require(`@/assets/img/${publicy.image}`)" :alt="publicy.subtitle" />
              </div>
              <div class="publicy-desktop-item-text">
                <h5 class="title">{{ publicy.subtitle }}</h5>
                <p class="description">{{ publicy.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return {
      publicys: []
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}homeCarouselData.json`)
        this.publicys = data;
      } catch (error) {
        console.log(error)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .publicy-desktop{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .content-title{
      padding: 50px;
    }

    .publicy-desktop-title{
      font-family: 'Avenir LT Pro Black';
      font-style: normal;
      font-weight: 750;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #003359;
      opacity: 0.8;
    }
    .publicy-desktop-image{
      padding-left: 60px;
    }
    .publicy-desktop-text-content{
      padding-right: 60px;
      .publicy-desktop-item{
        display: flex;
        margin-bottom: 48px;
        .publicy-desktop-item-image{
          width: 80px;
          margin-right: 30px;
        }
        .publicy-desktop-item-text{
          display: flex;
          flex-direction: column;
          .title{
            font-family: 'Avenir LT Pro Black';
            font-style: normal;
            font-weight: 750;
            font-size: 22px;
            line-height: 140%;
            color: #404040;
            opacity: 0.8;
          }
          .description{
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 140%;
            color: #404040;
            opacity: 0.8;
            margin: 0;
          }
        }
      }
    }
  }
</style>