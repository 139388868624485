<template>
<div class="row content-info">
  <div class="col-12 col-md-6">
    <h1 class="title">2. Resumen de tu compra</h1>
    <form @submit.prevent="saveInfo" id="form-prices" >
      <div class="card-resumen">
        <div class="card-resumen-body">
          <div class="item white">
            <span class="price-list">Precio de Lista</span><p class="price-list"> {{ selectedProgram.pricelist | moneyFormatter }}</p>
          </div>
          <div class="item grey">
            <span class="price-promo">Precio Promocional</span><p class="price-promo"> {{ selectedProgram.price | moneyFormatter  }}</p>
          </div>
          <div class="item white" v-if="selectedProgram.Metas.extra_month">
            <span class="price-list">+ {{ selectedProgram.Metas.quantity_month }} mes(es) adicional Gratis</span>
            <p class="price-list">$ 0</p>
          </div>
          <div class="item grey" v-if="couponApplied">
            <span class="price-discount">Descuento cupón ({{ cupon }})</span><p class="price-discount"> {{ discountValue() | moneyFormatter  }}</p>
          </div>
          <div class="item total">
            <span class="price-total">Total</span><p class="price-total"> {{subTotal | moneyFormatter }}</p>
          </div>
        </div>
      </div>

      <div class="referencia-quote mt-2">
        <span class="referencia-quote-label">Valor Cuota Referencial</span>
        <p class="referencia-quote-value ml-2"> {{calculateReferencialQuote()['quoteValue'] | moneyFormatter }}  ({{ calculateReferencialQuote()['quoteQantity'] }} Cuotas)</p>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="textura">
            <img src="@/assets/img/textura.png" alt="textura" v-if="media.isDesktop" />
            <img src="@/assets/img/texturaMobile.png" alt="textura" v-if="!media.isDesktop" />
            <div class="cover">
              <h3>¿Tienes un cupón de Descuento?</h3>
               <form @submit.prevent="validateCupon" class="form-coupon" >
                <div class="code-section">
                  <input class="cupon" type="text" v-model="cupon" :disabled="couponApplied" >
                  <button class="btn" type="submit"  :disabled="couponApplied">
                    Agregar
                  </button>
                </div>
                <div class="valid-coupon" v-if="messageCoupon" >
                  <img src="@/assets/svg/warning.svg" alt="warning" />
                  <span class="warning-coupon">Cupón no válido</span>
                </div>
               </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12">
          <div class="check-confirm form-group">
            <input type="checkbox" class="checkbox" v-model="acepTerms" >
            <span>Acepto los <a href="/terminos-condiciones.pdf" target="_blank" >Términos y Condiciones</a> del Contrato (Campo obligatorio para continuar)</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="form-grop">
            <button class="btn btn-medio"  type="submit" :disabled="!acepTerms" >
              Pagar <span class="price-button"> {{total | moneyFormatter }}</span> con Web Pay
            </button>
          </div>
        </div>
      </div>
    </form>
    <form :action="urlWebpay" method="POST" id="webpayForm" >
      <input type="hidden" name="token_ws" :value="token"/>
    </form>
  </div>
  <div class="col-12 col-md-1"></div>
  <div class="col-12 col-md-5">
    <div class="wrapper-resumen">
      <PlanInfo :alumno="true" />
    </div>
  </div>
</div>
</template>

<script>
// import recaptchaVisibility from "@/mixins/recaptchaVisibility";
import PlanInfo from "@/components/checkout/planInfo";
export default {
  components:{
    PlanInfo
  },
  data(){
    return {
      price: 0,
      pricelist: 0,
      freeMont: 0,
      referencialPrice: 0,
      subTotal: 0,
      total: 0,
      token: '',
      urlWebpay: '',
      discount: 0,
      acepTerms: false,
      cupon: '',
      messageCoupon: false
    }
  },
  mounted(){
    this.price = this.selectedProgram.price
    this.pricelist = this.selectedProgram.pricelist
    this.subTotal = (this.selectedProgram.price + this.freeMont + this.referencialPrice),
    this.total = (this.selectedProgram.price + this.freeMont + this.referencialPrice)
    console.log('couponApplied', this.couponApplied)
  },
  methods:{
    nextStep(){
      sessionStorage.setItem('step', 3)
      this.$emit('next', 3)
    },
    async saveInfo(){
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login')
        if (token) {
          const result = await this.Axios.post("/verify-recaptcha", {
            recaptchaToken: token
          });
          if (result.data.success) {
            try {
              const { data } = await this.Axios.post("/webpay/token", {
                buyOrder: this.order.buyOrder,
                sessionId: this.order.sessionId,
                amount: Math.round(this.total),
                returnUrl : `${process.env.VUE_APP_BASE_URL}payment-result`
              });
              this.token = data.token
              this.urlWebpay = data.url
              await this.Axios.put("/orders",
                {
                  ...this.order, 
                  status: 'pre-payment', tokenWs: 
                  data.token,
                  formAction: this.urlWebpay, 
                  sessionState: JSON.stringify(this.order.sessionState),
                  total: Math.round(this.total),
                  discount: (this.pricelist - this.price),
                  webPayUrlRedirection: data.url,
                  coupon: this.couponApplied ? this.cupon : null
                }
              ).then(async (order)=> {
                const hoy = new Date()
                localStorage.setItem('lastTime', hoy.toString());
                await this.$store.dispatch("setOrderInfo", order.data);
                document.getElementById("webpayForm").submit();
              });
            } catch (error) {
              console.log(error)
              alert('Error de solicitud')
            }
          }
        }
    },
    calculateReferencialQuote(){
      const program = this.selectedProgram
      if(program.Metas.quantity_month_quote){
        return {
          quoteValue: (this.subTotal / program.Metas.quantity_month_quote),  
          quoteQantity: program.Metas.quantity_month_quote
        } 
      }
      return {quoteValue: 0,  quoteQantity: 0}
    },
    async validateCupon(){
      if(this.cupon === ''){
        return
      } else {
        try {
          const { data } = await this.Axios.get(`/coupons`, {params: {
            cupon: this.cupon,
            priceListVersion_id: this.selectedProgram.priceListVersionId,
            products_id: this.selectedProgram.pdvId,
          }})
          console.log(data)
          if(data.validCupon){
            this.messageCoupon = false
            this.subTotal = data.precioTotal
            this.total = data.precioTotal
            this.discount = data.valorDescuento
            this.$store.dispatch("setCouponApplied",true);
            if(!this.media.isDesktop){
              this.goToPrices()
            }
          } else {
            this.messageCoupon = true
            this.$store.dispatch("setCouponApplied",false);
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    discountValue(){
      //return (this.total - (this.pricelist - this.price))
      return this.price -(this.pricelist - this.discount)
    },
    goToPrices(){
      const scrollDiv = document.getElementById("form-prices").offsetTop + 300;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    },
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    selectedProgram() {
      return this.$store.state.selectedProgram;
    },
    selectedLocation() {
      return this.$store.state.selectedLocation;
    },
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    couponApplied(){
      /*const data = localStorage.getItem("coupon");
      return data ? true : false*/
      return this.$store.state.couponApplied;
    }
  },
  //mixins: [recaptchaVisibility],
}
</script>

<style lang="scss" scoped>

  .content-info{
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  .wrapper-resumen{
    display: flex;
    justify-content: flex-end;
  }

  .title{
    font-family: 'Avenir LT Pro Black';
    font-style: normal;
    font-weight: 750;
    font-size: 24px;
    line-height: 120%;
    color: #003359;
    margin-bottom: 20px !important;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  .card-resumen{
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 8px;
    .card-resumen-body{
      padding: 10px;
      display: flex;
      flex-direction: column;
    }
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 10px;
      p{
        margin: 0;
      }
    }
    .white{
      background: #fff;
    }
    .grey{
      background: #FCFCFC;
    }
    .total{
      background: rgba(166, 184, 197, 0.5);
    }
    .price-list{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #0082A9;
    }
    .price-promo{
      font-family: 'Avenir LT Pro Black';
      font-style: normal;
      font-weight: 750;
      font-size: 18px;
      line-height: 140%;
      color: #003359;
    }
    .price-discount{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #40A1BE;
    }
    .price-grey{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #808080;
    }
    .price-total{
      font-family: 'Avenir LT Pro Black';
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28.8px;
      color: #003359;
    }
  }

  .check-confirm{
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
     align-items: flex-start;
    }
    .checkbox{
      margin-right: 10px;
      margin-top: 5px;
      width: 15px;
      height: 15px;
    }
    span{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #000000;
    }
  }

  .btn-medio{
    width: 100%;
    background: #003359;
    border-radius: 4px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #FFFFFF;
    &:hover{
      color: #FFFFFF;
    }
    .price-button, span{
      font-family: 'Avenir LT Pro Black' !important;
      font-style: normal;
      font-weight: 750 !important;
      margin: 0 5px;
    }
  }

  .textura{
    /*opacity: 0.2;*/
    background: #A6B8C5;
    border-radius: 8px;
    margin: 40px 0;
    height: auto; 
    position: relative;
    img{
      width: 100%;
    }
    @media (max-width: 768px) {
      margin-right: -15px;
      margin-left: -15px;
      border-radius: 0px;
    }
  }
  .cover{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;

    h3{
      font-family: 'Avenir LT Pro Black';
      font-style: normal;
      font-weight: 750;
      font-size: 20px;
      line-height: 140%;
      color: #003359;
    }
    .code-section{
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    .w-50{
      width: 50%;
    }

    .cupon{
      width: 157px;
      height: 50px;
      margin-right: 15px;
      background: #FFFFFF;
      border: 1px solid #A6B8C5;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      color: #406683;
      outline: none;
      padding-left: 20px;
    }

    button{
      width: 157px;
      height: 50px;
      background: #F12C3E;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #FFFFFF;
    }
    
  }

  .referencia-quote{
    display: flex;
    .referencia-quote-label, .referencia-quote-value{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #808080;
    }
    .referencia-quote-value{
      font-weight: bold !important;
    }
  }

  .form-coupon{
    display: flex;
    flex-direction: column;
  }

  .valid-coupon{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;

    img{
      width: 24px;
      height: 24px;
    }

    .warning-coupon{
      color: #F12C3E;
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

</style>