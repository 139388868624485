<template>
<div class="row content-info">
  <div class="col-12 col-md-7">
    <h1 class="title">1. Datos del Alumno</h1>
    <form id="formStep1" @submit.prevent="saveInfo" >

      <div class="row">
        <div class="col-12 col-md-8">
          <div class="form-group">
            <div class="float-container" id="container-name" :class="{active: student.name != ''}" >
              <label for="name">Nombre</label>
              <input id="name" type="text" autocomplete="off" class="input-float"
                v-model="student.name"
                maxlength="25" 
                onkeypress="return /[a-zA-ZáéíóúÁÉÍÓÚñÑ ]/.test(event.key)"
              >
            </div>
            <div v-if="submitted && !$v.student.name.required" class="text-error">
              Este campo es requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="form-group">
            <div class="float-container" id="container-lastName" :class="{active: student.lastName != ''}" >
              <label for="lastName">Apellido</label>
              <input id="lastName" type="text" autocomplete="off" class="input-float"
                v-model="student.lastName"
                maxlength="25" 
                onkeypress="return /[a-zA-ZáéíóúÁÉÍÓÚñÑ ]/.test(event.key)"
              >
            </div>
            <div v-if="submitted && !$v.student.lastName.required" class="text-error">
              Este campo es requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-4">
          <div class="form-group">
            <div class="float-container" id="container-rut" :class="{active: student.rut != ''}">
              <label for="rut">RUT</label>
              <!--input id="rut" type="text" autocomplete="off" class="input-float" 
                v-model="student.rut"
              -->
              <!--the-mask
                id="rut"
                class="input-float"
                :mask="['#######-#', '########-#']"
                :masked="true"
                v-model="student.rut"
                type="text"
                name="rut"
              ></the-mask-->

              <the-mask 
                :mask="['#######-F', '########-F']" 
                :tokens="rutTokens" 
                id="rut"
                class="input-float"
                :masked="true"
                v-model="student.rut"
                type="text"
                name="rut"
              />

            </div>
            <div v-if="submitted && !$v.student.rut.required" class="text-error">
              Este campo es requerido.
            </div>
            <div v-if="submitted && !$v.student.rut.validateRut" class="text-error">
              Formato de Rut inválido.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="form-group">
            <div class="float-container" id="container-address" :class="{active: student.address != ''}">
              <label for="address">Dirección</label>
              <input id="address" type="search" class="input-float" v-model="student.address" autocomplete="off" 
                @keyup="openSuggestion = true"
                @click="openSuggestion = true"
                @focus="focusGeneral($event)"
                @blur="blurGeneral($event)"
                v-click-outside="externalClick"
              >
            </div>
            <ul class="list-group shadow vbt-autcomplete-list list-autocomplete" v-if="openSuggestion">
              <a href="#" class="list-group-item list-group-item-action" aria-current="true" v-for="(result, i) in searchResults" :key="i" 
                @click.prevent="selectAddress(result)"
              >
                <b>{{ result }}</b>
              </a>
            </ul>
            <div v-if="submitted && !$v.student.address.required" class="text-error">
              Este campo es requerido.
            </div>
            <div v-if="checkStudentAdress && !validateAddressStudent()" class="text-error">
              Dirección inválida, debe elegir una de las sugeridas del listado.
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="form-group">
            <div class="float-container" id="container-address2" :class="{active: student.address2 != ''}" >
              <label for="address2">N° de Casa/Dpto</label>
              <input id="address2" type="text" autocomplete="off" class="input-float" 
                v-model="student.address2"
              >
            </div>
            <div v-if="submitted && !$v.student.address2.required" class="text-error">
              Este campo es requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="form-group">
            <div class="float-container" id="container-email" :class="{active: student.email != ''}" >
              <label for="email">Email</label>
              <input id="email" type="email" autocomplete="off" class="input-float" 
                v-model="student.email"
                maxlength="40"
              >
            </div>
            <div v-if="submitted && !$v.student.email.required" class="text-error">
              Este campo es requerido.
            </div>
            <div v-if="submitted && !$v.student.email.email" class="text-error">
              Formato de email inválido.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-4">
          <div class="form-group">
            <div class="float-container" id="container-phone" :class="{active: student.phone != ''}" >
              <label for="phone">Teléfono</label>
              <!--input id="phone" type="phone" autocomplete="off" class="input-float" 
                v-model="student.phone"
              -->
              <the-mask
                id="phone"
                class="input-float"
                mask="+569 #### ####"
                :masked="true"
                v-model="student.phone"
                type="tel"
                name="phone"
              ></the-mask>
            </div>
            <div v-if="submitted && !$v.student.phone.required" class="text-error">
              Este campo es requerido.
            </div>
            <div class="text-error" v-if="submitted && !$v.student.phone.minLength">Teléfono inválido</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12">
          <div class="check-confirm form-group">
            <input type="checkbox" class="checkbox" v-model="caretakerEqualStudent"
              @change="checkCaretaker"
            >
            <span>Confirmo que los datos del alumno y el apoderado son los mismos</span>
          </div>
        </div>
      </div>

      <div class="w-100" v-if="!caretakerEqualStudent">
        <h1 class="title mt-3">2. Datos del Apoderado</h1>
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="form-group">
              <div class="float-container" id="container-nameC" :class="{active: financingParty.name != ''}" >
                <label for="nameC">Nombre</label>
                <input id="nameC" type="text" autocomplete="off" class="input-float"
                  v-model="financingParty.name"
                  maxlength="25" 
                  onkeypress="return /[a-zA-ZáéíóúÁÉÍÓÚñÑ ]/.test(event.key)"
                >
              </div>
              <div v-if="submitted && !$v.financingParty.name.required" class="text-error">
                Este campo es requerido.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="form-group">
              <div class="float-container" id="container-lastNameC" :class="{active: financingParty.lastName != ''}" >
                <label for="lastNameC">Apellido</label>
                <input id="lastNameC" type="text" autocomplete="off" class="input-float"
                  v-model="financingParty.lastName"
                  maxlength="25" 
                  onkeypress="return /[a-zA-ZáéíóúÁÉÍÓÚñÑ ]/.test(event.key)"
                >
              </div>
              <div v-if="submitted && !$v.financingParty.lastName.required" class="text-error">
                Este campo es requerido.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-4">
            <div class="form-group">
              <div class="float-container" id="container-rutC" :class="{active: financingParty.rut != ''}">
                <label for="rutC">RUT</label>
                <!--input id="rutC" type="text" autocomplete="off" class="input-float" 
                  v-model="financingParty.rut"
                -->
                <the-mask
                  id="rutC"
                  class="input-float"
                  :mask="['FFFFFFF-F', 'FFFFFFFF-F']" 
                  :tokens="rutTokens"
                  :masked="true"
                  v-model="financingParty.rut"
                  type="text"
                  name="rutC"
                ></the-mask>
                
              </div>
              <div v-if="submitted && !$v.financingParty.rut.required" class="text-error">
                Este campo es requerido.
              </div>
              <div v-if="submitted && !$v.financingParty.rut.validateRut" class="text-error">
                Formato de Rut inválido.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!--div class="col-12 col-md-8">
            <div class="form-group">
              <div class="float-container" id="container-addressC" :class="{active: financingParty.address != ''}" >
                <label for="addressC">Dirección</label>
                <input id="addressC" type="text" autocomplete="off" class="input-float" v-model="financingParty.address" >
              </div>
              <div v-if="submitted && !$v.financingParty.address.required" class="text-error">
                Este campo es requerido.
              </div>
            </div>
          </div-->

          <div class="col-12 col-md-8">
            <div class="form-group">
              <div class="float-container" id="container-addressC" :class="{active: financingParty.address != ''}">
                <label for="addressC">Dirección</label>
                <input id="addressC" type="search" class="input-float" v-model="financingParty.address"  autocomplete="off" 
                  @keyup="openSuggestionC = true"
                  @click="openSuggestionC = true"
                  @focus="focusGeneral($event)"
                  @blur="blurGeneral($event)"
                  v-click-outside="externalClickC"
                >
              </div>
              <ul class="list-group shadow vbt-autcomplete-list list-autocomplete" v-if="openSuggestionC">
                <a href="#" class="list-group-item list-group-item-action" aria-current="true" v-for="(result, i) in searchResultsC" :key="i" 
                  @click.prevent="selectAddressC(result)"
                >
                  <b>{{ result }}</b>
                </a>
              </ul>
              <div v-if="submitted && !$v.financingParty.address.required" class="text-error">
                Este campo es requerido.
              </div>
              <div v-if="checkFinancingAdress && !validateAddressFinancing()" class="text-error">
                Dirección inválida, debe elegir una de las sugeridas del listado.
              </div>
            </div>
          </div>


          <div class="col-6 col-md-4">
            <div class="form-group">
              <div class="float-container" id="container-address2C" :class="{active: financingParty.address2 != ''}" >
                <label for="address2C">N° de Casa/Dpto</label>
                <input id="address2C" type="text" autocomplete="off" class="input-float" 
                  v-model="financingParty.address2"
                >
              </div>
              <div v-if="submitted && !$v.financingParty.address2.required" class="text-error">
                Este campo es requerido.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="form-group">
              <div class="float-container" id="container-emailC" :class="{active: financingParty.email != ''}" >
                <label for="emailC">Email</label>
                <input id="emailC" type="email" autocomplete="off" class="input-float" 
                  v-model="financingParty.email"
                  maxlength="40"
                >
              </div>
              <div v-if="submitted && !$v.financingParty.email.required" class="text-error">
                Este campo es requerido.
              </div>
              <div v-if="submitted && !$v.financingParty.email.email" class="text-error">
                Formato de email inválido.
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6 col-md-4">
            <div class="form-group">
              <div class="float-container" id="container-phoneC" :class="{active: financingParty.phone != ''}" >
                <label for="phone">Teléfono</label>
                <!--input id="phoneC" type="phone" autocomplete="off" class="input-float" 
                  v-model="financingParty.phone"
                -->
                <the-mask
                  id="phoneC"
                  class="input-float"
                  mask="+569 #### ####"
                  :masked="true"
                  v-model="financingParty.phone"
                  type="tel"
                  name="phoneC"
                ></the-mask>
              </div>
              <div v-if="submitted && !$v.financingParty.phone.required" class="text-error">
                Este campo es requerido.
              </div>
              <div class="text-error" v-if="submitted && !$v.financingParty.phone.minLength">Teléfono inválido</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12">
          <div class="form-grop">
            <button class="btn btn-medio" type="submit" >
              Continuar con medios de pago
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-12 col-md-5">
    <div class="wrapper-resumen">
      <PlanInfo />
    </div>
  </div>
</div>
</template>

<script>
import Cookies from "js-cookie"
import {required, email, minLength } from 'vuelidate/lib/validators'
import PlanInfo from "@/components/checkout/planInfo"
import vClickOutside from 'v-click-outside'
// import recaptchaVisibility from "@/mixins/recaptchaVisibility";

const validateRut = (value) =>{
  if(value != '')
  {
    let rut = value.split('-')[0];
    rut = rut.replace(/\./g, "");
    let digit = value.split('-')[1];
    return dv(rut) == digit;
  }
  return true
}
const dv = (T) => {
  let M = 0;
  let S = 1;
  for (; T; T = Math.floor(T / 10)) {
    S = (S + T % 10 * (9 - M++ % 6)) % 11;
  }
  return S ? S - 1 : 'k';
}

export default {
  components:{
    PlanInfo,
  },
  data(){
    return {
      submitted: false,
      student: {
        name: '',
        lastName: '',
        rut: '',
        address: '',
        address2: '',
        email: '',
        phone: ''
      },
      caretakerEqualStudent: false,
      financingParty: {
        name: '',
        lastName: '',
        rut: '',
        address: '',
        address2: '',
        email: '',
        phone: ''
      },
      rutTokens: {
        F: {
          pattern: /[0-9a-kK]/,
          transform: v => v.toLocaleLowerCase()
        },
        '#': {pattern: /\d/},
      },
      location: '',
      searchResults: [],
      searchResultsC: [],
      service: null,
      openSuggestion: false,
      openSuggestionC: false,
      validStudentAdress: false,
      checkStudentAdress: false,
      validFinancingAdress: false,
      checkFinancingAdress: false
    }
  },
  validations: {
    student: {
      name: {required},
      lastName: {required},
      rut: {required, validateRut},
      address: {required},
      address2: {required},
      email: {required, email},
      phone: {required, minLength: minLength(14)}
    },
    financingParty: {
      name: {required},
      lastName: {required},
      rut: {required, validateRut},
      address: {required},
      address2: {required},
      email: {required, email},
      phone: {required, minLength: minLength(14)}
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  metaInfo () {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAP_KEY}&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.mapsInit() // will declare it in methods
      }]
    }
  },
  watch: {
    'student.address': function (newValue){
      if (newValue != '') {
        this.service.getPlacePredictions({
          input: this.student.address
        }, this.displaySuggestions)
      } else {
        this.searchResults = []
      }
    },
    'financingParty.address': function (newValue){
      if (newValue != '') {
        this.service.getPlacePredictions({
          input: this.financingParty.address
        }, this.displaySuggestionsC)
      } else {
        this.searchResults = []
      }
    },
  },
  mounted(){
    this.initializersInputs()
    this.initializersInputsFinancingParty()
    if(sessionStorage.getItem('lead')){
      const lead = JSON.parse(sessionStorage.getItem('lead'))
      this.student.email = lead.email
      this.student.name = lead.name 
      this.student.lastName = lead.lastname
      this.student.phone = lead.phone  
    }
  },
  methods:{
    mapsInit () {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions (predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions.map(prediction => prediction.description)
    },
    displaySuggestionsC (predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResultsC = []
        return
      }
      this.searchResultsC = predictions.map(prediction => prediction.description)
    },
    initializersInputs(){
      const namesField = document.getElementById('name');
      const namesContainer = document.getElementById('container-name');
      namesField.addEventListener('focus', () => {
        namesContainer.classList.add('active');
      });
      namesField.addEventListener('blur', () => {
        if(namesField.value == ''){
          namesContainer.classList.remove('active');
        }
      });
      const lastNameField = document.getElementById('lastName');
      const lastNameContainer = document.getElementById('container-lastName');
      lastNameField.addEventListener('focus', () => {
        lastNameContainer.classList.add('active');
      });
      lastNameField.addEventListener('blur', () => {
        if(lastNameField.value == ''){
          lastNameContainer.classList.remove('active');
        }
      });
      const rutField = document.getElementById('rut');
      const rutContainer = document.getElementById('container-rut');
      rutField.addEventListener('focus', () => {
        rutContainer.classList.add('active');
      });
      rutField.addEventListener('blur', () => {
        if(rutField.value == ''){
          rutContainer.classList.remove('active');
        }
      });
      const addressField = document.getElementById('address');
      const addressContainer = document.getElementById('container-address');
      addressField.addEventListener('focus', () => {
        addressContainer.classList.add('active');
      });
      addressField.addEventListener('blur', () => {
        if(addressField.value == ''){
          addressContainer.classList.remove('active');
        }
      });
      const address2Field = document.getElementById('address2');
      const address2Container = document.getElementById('container-address2');
      address2Field.addEventListener('focus', () => {
        address2Container.classList.add('active');
      });
      address2Field.addEventListener('blur', () => {
        if(address2Field.value == ''){
          address2Container.classList.remove('active');
        }
      });
      const emailField = document.getElementById('email');
      const emailContainer = document.getElementById('container-email');
      emailField.addEventListener('focus', () => {
        emailContainer.classList.add('active');
      });
      emailField.addEventListener('blur', () => {
        if(emailField.value == ''){
          emailContainer.classList.remove('active');
        }
      });
      const phoneField = document.getElementById('phone');
      const phoneContainer = document.getElementById('container-phone');
      phoneField.addEventListener('focus', () => {
        phoneContainer.classList.add('active');
      });
      phoneField.addEventListener('blur', () => {
        if(phoneField.value == ''){
          phoneContainer.classList.remove('active');
        }
      });
    },
    initializersInputsFinancingParty(){
      const namesField = document.getElementById('nameC');
      const namesContainer = document.getElementById('container-nameC');
      namesField.addEventListener('focus', () => {
        namesContainer.classList.add('active');
      });
      namesField.addEventListener('blur', () => {
        if(namesField.value == ''){
          namesContainer.classList.remove('active');
        }
      });
      const lastNamesField = document.getElementById('lastNameC');
      const lastNamesContainer = document.getElementById('container-lastNameC');
      lastNamesField.addEventListener('focus', () => {
        lastNamesContainer.classList.add('active');
      });
      lastNamesField.addEventListener('blur', () => {
        if(namesField.value == ''){
          lastNamesContainer.classList.remove('active');
        }
      });
      const rutField = document.getElementById('rutC');
      const rutContainer = document.getElementById('container-rutC');
      rutField.addEventListener('focus', () => {
        rutContainer.classList.add('active');
      });
      rutField.addEventListener('blur', () => {
        if(rutField.value == ''){
          rutContainer.classList.remove('active');
        }
      });
      const addressField = document.getElementById('addressC');
      const addressContainer = document.getElementById('container-addressC');
      addressField.addEventListener('focus', () => {
        addressContainer.classList.add('active');
      });
      addressField.addEventListener('blur', () => {
        if(addressField.value == ''){
          addressContainer.classList.remove('active');
        }
      });
      const address2Field = document.getElementById('address2C');
      const address2Container = document.getElementById('container-address2C');
      address2Field.addEventListener('focus', () => {
        address2Container.classList.add('active');
      });
      address2Field.addEventListener('blur', () => {
        if(address2Field.value == ''){
          address2Container.classList.remove('active');
        }
      });
      const emailField = document.getElementById('emailC');
      const emailContainer = document.getElementById('container-emailC');
      emailField.addEventListener('focus', () => {
        emailContainer.classList.add('active');
      });
      emailField.addEventListener('blur', () => {
        if(emailField.value == ''){
          emailContainer.classList.remove('active');
        }
      });
      const phoneField = document.getElementById('phoneC');
      const phoneContainer = document.getElementById('container-phoneC');
      phoneField.addEventListener('focus', () => {
        phoneContainer.classList.add('active');
      });
      phoneField.addEventListener('blur', () => {
        if(phoneField.value == ''){
          phoneContainer.classList.remove('active');
        }
      });
    },
    nextStep(){
      sessionStorage.setItem('step', 2)
      this.$emit('next', 2)
    },
    checkCaretaker(event){
      const checked = event.target.checked
      if(checked){
        this.financingParty = {...this.student}
      }else {
        /*this.financingParty =  {
          names: '',
          rut: '',
          address: '',
          address2: '',
          email: '',
          phone: ''
        }*/
      }
    },
    saveInfo(){
      this.submitted = true
      this.$v.student.$touch()
      this.$v.financingParty.$touch()
      if(this.caretakerEqualStudent){
        if(!this.$v.student.$invalid && this.validateAddressStudent()){
          this.applyCaptcha()
        }
      } else {
        if(!this.$v.student.$invalid && !this.$v.financingParty.$invalid && this.validateAddressStudent() && this.validateAddressFinancing()){
          this.applyCaptcha()
        }
      }
    },
    async applyCaptcha(){
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registerStudent");
      if (token) {
        const result = await this.Axios.post("/verify-recaptcha", {
          recaptchaToken: token
        });
        if (result.data.success) {
          this.saveFormInfo()
        }
      }
    },
    async saveFormInfo() {
      const res = await this.createStudent();
      await this.$store.dispatch("addStudent", {...res.data});
      await this.$store.dispatch("addFinancingPartyInfo",this.financingParty);
      await this.$store.dispatch("addCaretakerInfo",this.financingParty);
      let sessionState = {
        students: this.students, 
        financingParty: this.$store.state.financingParty, 
        caretaker: this.$store.state.caretaker
      };
      await this.$store.dispatch("addInfoToFullOrder", {
        sessionState: JSON.stringify(sessionState)
      });
      const { data: order } = await this.Axios.post("/orders", {
        ...this.$store.state.fullOrder,
        initialStudentId: this.students[0].id,
        financingPartyId: this.students[0].financingPartyId,
        locationId: this.selectedLocation.id,
        programId: this.selectedProgram.id,
        vid: this.lead.hubspotId || null,
        month: this.getNumberModality(this.selectedProgram.name)
        // month: this.selectedProgram.name
      });
      await this.$store.dispatch("setOrderInfo", order);
      Cookies.set("sessionId", order.sessionId);
      localStorage.ordenID = order.id;
      this.nextStep();
    },
    createStudent() {
      return new Promise((resolve, reject) => {
        this.Axios.post("/students", {
          ...this.student,
          financingParty:  this.caretakerEqualStudent ? this.student : this.financingParty
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            if (error.response.status === 400) {
              if (
                error.response.data.message ===
                "Error - Email address already in use!"
              ) {
                alert(
                  "El correo: " +
                    error.response.data.problemField.email +
                    " ya se encuentra registrado"
                );
              }
            }
            reject(error);
          });
      });
    },
    getNumberModality(text){
      let numb = text.match(/\d/g);
      numb = numb.join("");
      const int = parseInt(numb, 10)
      return int;
    },
    validateAddressStudent(){
      const arrayText = this.student.address.split(",");
      return arrayText.length > 2 ? true : false
    },
    validateAddressFinancing(){
      const arrayText = this.financingParty.address.split(",");
      return arrayText.length > 2 ? true : false
    },
    validateAddress(tipo, value){
      if(tipo === 'address'){
        this.checkStudentAdress = value
      } else if(tipo === 'addressC') {
        this.checkFinancingAdress = value
      }
    },
    selectAddress(result){
      this.student.address = result
      this.openSuggestion = false
      this.checkStudentAdress = true
    },
    selectAddressC(result){
      this.financingParty.address = result
      this.openSuggestionC = false
      this.checkFinancingAdress = true
    },
    externalClick(){
      this.openSuggestion = false
    },
    externalClickC(){
      this.openSuggestionC = false
    },
    focusGeneral(e){
      const element = document.getElementById(e.target.id)
      const parent = element.parentElement
      parent.classList.add('active');
    },
    blurGeneral(e){
      const element = document.getElementById(e.target.id)
      const parent = element.parentElement
      if(e.target.value == ''){
        parent.classList.remove('active')
        this.validateAddress(e.target.id, false)
      } else {
        this.validateAddress(e.target.id, true)
      }
    }
  },
  computed: {
    selectedProgram() {
      return this.$store.state.selectedProgram;
    },
    selectedLocation() {
      return this.$store.state.selectedLocation;
    },
    students() {
      return this.$store.state.students;
    },
    lead(){
      return JSON.parse(sessionStorage.getItem('lead'))
    }
  },
  //mixins: [recaptchaVisibility],
}
</script>

<style lang="scss" scoped>

  .content-info{
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  .title{
    font-family: 'Avenir LT Pro Black';
    font-style: normal;
    font-weight: 750;
    font-size: 24px;
    line-height: 120%;
    color: #003359;
    margin-bottom: 20px !important;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  }

  .check-confirm{
    display: flex;
    align-items: flex-start;
    .checkbox{
      margin-right: 10px;
      margin-top: 6px;
    }
    span{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #000000;
    }
  }

  .btn-medio{
    width: 100%;
    background: #003359;
    border-radius: 4px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #FFFFFF;
    &:hover{
      color: #FFFFFF;
    }
  }

  .wrapper-resumen{
    display: flex;
    justify-content: flex-end;
  }
  .w-100{
    width: 100%;
  }

  .custom-input{
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #000;
    font-size: 16px;
    line-height: 18px;
    font-family: "ubunturegular", Arial, sans-serif;
  }

  .list-autocomplete{
    position: absolute;
    z-index: 1000;
    right: 15px;
    left:  15px;
    margin-top: 2px;
  }

</style>