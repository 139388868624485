<template>
  <div class="main-content">
    <Header :currentUrl="currentRoute" />
      <slot></slot>
    <Contacto />
    <Footer v-if="currentRoute != '/checkout' || currentRoute != '/payment-result'"  />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Contacto from "@/components/Contacto";
export default {
  components:{
    Header,
    Footer,
    Contacto
  },
  data(){
    return {
      currentRoute: this.$route.fullPath
    }
  },
  created(){
    this.$store.dispatch('setInitialStudentInfo');
    this.$store.dispatch('setInitialStudents');
    this.$store.dispatch('setInitialCaretakerInfo');
    this.$store.dispatch('setInitialFinancingPartyInfo');
    this.$store.dispatch('setInitialSelectedProgramInfo');
    this.$store.dispatch('setInitialSubject');
    this.$store.dispatch('setInitialSelectedCourses');
    this.$store.dispatch('setInitialSelectedLocation');
    this.$store.dispatch('setInitialFullOrderData');
    this.$store.dispatch('setInitialBackpackVisible');
    this.$store.dispatch('setInitialStudentsWithPrices');
    this.$store.dispatch('setInitialCoursesNotAvailables');
    this.$store.dispatch('setInitialProgramDataForRecommend');
    this.$store.dispatch('setInitialOrder');
    this.$store.dispatch('setInitialLeadInfo');
  },
  mounted(){
    const lastTime = localStorage.getItem('lastTime');
    if(lastTime){ // validar si ya paso una hora para limpiar el local storage
      const fecha = new Date(lastTime)
      const hoy = new Date()
      let timeDiff = hoy - fecha; 
      const segundos = timeDiff/ 1000
      const minutos = segundos / 60
      if(minutos > 15){
        localStorage.clear();
        sessionStorage.removeItem('step');
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
  .modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
</style>