<template>
  <div class="contacto" v-if="!media.isDesktop">
    <div class="social-text">
      <h3 class="contacto-title">¿Tienes dudas?</h3>
      <span class="contacto-subtitle">Comunicate con nosotros</span>
    </div>
    <a href="tel:+56228713695" target="_blank" class="social-icons">
      <div class="icon">
        <img src="@/assets/svg/boton-call.svg" alt="boton-call" />
      </div>
      <a href="tel:800203636" class="icon">
        <img src="@/assets/svg/boton-call.svg" alt="boton-call" />
      </a>
      <a href="mailto:info@wsi.cl" class="icon">
        <img src="@/assets/svg/boton-email.svg" alt="boton-email" />
      </a>
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  },
}
</script>

<style lang="scss" scoped>
   .contacto{
    height: 198px;
    background-color: #0082A9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .social-text{
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .contacto-title{
        font-family: A'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 24px;
        line-height: 140%;
        color: #fff;
        display: flex;
        justify-content: center;
      }
      .contacto-subtitle{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;
      }
    }
    .social-icons{
      display: flex;
      .icon{
        width: 42px;
        margin: 12px;
      }
    }
  }
</style>