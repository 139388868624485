<template>
  <layout-default>
    <div class="politicas wse-page">
      <div class="banner">

      </div>
      <div class="content-garantee mb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="title-garantee mt-5">
                Política de Privacidad y Confidencialidad
              </h1>

              <p class="text-garantee">
                A Continuación se expresan los detalles relacionados con el envío de comunicaciones por parte de Wall Street English Chile a sus usuarios vía correo electrónico y la entrega de información por parte de los usuarios a Wall Street English Chile por la misma vía.
              </p>
              <p class="text-garantee">
                Es importante que sepa que en cualquier momento puede solicitar ser removido de nuestras listas y de esta forma queda interrumpido el proceso de envío para comunicaciones futuras.
              </p>

              <h2 class="subtitle-garantee">
                Declaraciones de privacidad y confidencialidad
              </h2>
               <p class="text-garantee">
                Las presentes declaraciones, tienen por objeto poner en conocimiento de los usuarios los alcances de la protección integral de los datos personales asentados en archivos, registros, bancos o bases de datos, u otros medios técnicos de tratamiento de datos, que se recaben a través de correo electrónico o e-mailing, en pos de un adecuado respeto al derecho a la intimidad de las personas, así como también al libre acceso a la información que sobre las mismas pueda eventualmente registrarse.
              </p>
              <p class="text-garantee">
                En este sentido, Wall Street English Chile garantiza que los datos recabados a través de este correo electrónico, serán tratados siempre de modo tal que quede resguardada la finalidad tuitiva que la protección de los datos personales consagra, siendo utilizados únicamente con la finalidad aquí declarada, en conformidad a la Ley 19.628 sobre Protección de la Vida Privada y demás normativa vigente. Nos comprometemos a no vender, difundir o distribuir de cualquier otro modo sus Datos Personales a terceros, excepto en los casos dispuestos en la presente Política de Privacidad.
              </p>
              
              <h2 class="subtitle-garantee">Voluntariedad en la entrega de datos</h2>
              <p class="text-garantee">
                Toda información o datos personales entregados por los receptores de correos electrónicos enviados por Wall Street, en los mismos correos, son entregados de libre y voluntariamente, y con conocimiento de los usos y finalidades que aquí se declaran.
              </p>
              <h2 class="subtitle-garantee">Finalidad de los datos recolectados</h2>
              <p class="text-garantee">
                La recolección y tratamiento de datos de carácter personal tiene por finalidad la prestación, gestión, administración, personalización, actualización y mejora del servicio de Wall Street English Chile. Los datos podrán ser utilizados para el envío personalizado de las comunicaciones a los usuarios, en lo referente a los servicios y contenidos brindados por la empresa. Únicamente en cuanto a las reseñas sobre servicio y la identidad de quien las emite, podrán ser publicadas en redes sociales, páginas web de propiedad de Wall Street English Chile y buscadores en internet, únicamente con la finalidad de dar a conocer al público en general las experiencias de nuestros usuarios.
              </p>
              <h2 class="subtitle-garantee">Autorización de uso</h2>
              <p class="text-garantee">
                El usuario que facilitara sus datos personales, autoriza expresamente a Wall Street English Chile para el uso de los datos aportados con los fines aquí expuestos.
              </p>

              <h2 class="subtitle-garantee">Derechos de los titulares de los datos</h2>
              <p class="text-garantee">
                A su vez, y como consecuencia de la garantía de los derechos amparados, se expresa también como finalidad, la de permitir en todo momento el acceso a la información por parte de las personas vinculadas a los datos registrados. De este modo, el usuario podrá ejercitar los derechos de acceso, rectificación o cancelación de datos.
              </p>
              <p class="text-garantee">
                Si desea ponerse en contacto con nosotros en relación al uso de sus Datos Personales o bien ejercer sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus Datos Personales, por favor, envíe un correo electrónico a atencionalcliente@wsi.cl o dirija una comunicación escrita a Avenida Apoquindo 5142, Comuna de Las Condes, Región Metropolitana, anotando en todo caso el nombre de la página web o la vía por la cual introdujo sus datos, y acompañando una copia de su documento nacional de identidad. Las solicitudes para cancelar los datos personales estarán sujetas a cualquier requerimiento legal aplicable o clasificación de documentos u obligaciones de retención documental que nos sean impuestas.
              </p>

              <h2 class="subtitle-garantee">Seguridad y confidencialidad</h2>
              <p class="text-garantee">
                Con el fin de garantizar la seguridad y confidencialidad de los Datos Personales que recopilamos online, utilizamos redes de datos protegidas mediante cortafuegos estándar protegidos con contraseña. Durante el tratamiento de sus Datos Personales, tomamos medidas diseñadas adecuadamente para proteger dicha información ante posibles pérdidas, uso inadecuado, acceso no autorizado, revelación, alteración o destrucción.
              </p>

              <h2 class="subtitle-garantee">Envíos de mensajes y comunicaciones</h2>
              <p class="text-garantee">
                Serán enviadas comunicaciones por parte de Wall Street English Chile para presentar un producto, servicio, información corporativa, contenidos que consideremos podría ser de interés, mensajes destinados a confirmar su inscripción, para pedir su opinión respecto de nuestro servicio o para notificarlo respecto a los cambios de los términos y condiciones o de esta Política de Privacidad. Si usted decidiera dejar de recibir el servicio de envío de mensajes, podrá realizarlo en forma automática a través de un hipervínculo que se incorpora en todas nuestras comunicaciones.
              </p>

              <h2 class="subtitle-garantee">Excepciones a nuestra Política de Privacidad y otras informaciones</h2>
              <p class="text-garantee">
                En cumplimiento de nuestras obligaciones legales, su Información Personal y/o cualquier otro tipo de información específica sobre Ud. a la que podamos acceder, en forma intencional o casual, y/o sobre su cuenta, podrá ser revelada a terceros a efectos de cumplir con procedimientos legales tal como una orden de allanamiento, una citación judicial, una orden judicial, o bien a requerimientos de autoridades administrativas, que puedan ser considerados válidos bajo las leyes aplicables. Asimismo, para nuestra protección, la suya y las de otros usuarios, también podrá ser revelada su Información Personal en aquellos casos que impliquen la existencia de un intento de ruptura de la seguridad, o de peligro físico o amenaza en contra suyo, nuestro o de terceros.
              </p>


            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default";
export default {
  components:{
    LayoutDefault
  },
   metaInfo: {
    title: 'Wall Street English',
    titleTemplate: '%s | Política de Privacidad y Confidencialidad'
  },
}
</script>

<style lang="scss" scoped>

.title-garantee{
  font-family: 'Avenir LT Pro Black';
  color: #003359;
  font-size: 36px;
  line-height: 150%;
  font-weight: 700;
  margin-bottom: 15px;
  opacity: 0.8;
}

.subtitle-garantee{
  font-family: 'Avenir LT Pro Black';
  font-style: normal;
  font-weight: 750;
  font-size: 24px;
  line-height: 140%;
  color: #003359;
  opacity: 0.8;
}

p, li{
  font-family: 'Avenir LT Pro Light';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: #003359;
}

ol {
  list-style-type: upper-alpha;
}

</style>