<template>
  <layout-default>
    <div class="result-pay wse-page" >
      <div class="fail" v-if="!success && !loaderPay">
        <div class="animation-fail">
          <img src="@/assets/img/fail-animation.gif" alt="fail-animation" />
        </div>
        <div class="title-content">
          <h2>¡Oh no!</h2>
        </div>
        <div class="text-content">
          <p>Parece que algo salió mal al procesar tu pago. Por favor vuelve a intentarlo</p>
        </div>
        <button class="btn btn-back" @click="tryAgain">Volver a intentar</button>

      </div>
      <div class="success" v-if="success && !loaderPay">
        <div class="container">
          <div class="content-success">
              <div class="header">
                <h3>¡Felicidades {{financingParty.name}}! Tu futuro está por cambiar</h3>
                <p>Te enviamos un email a  {{ financingParty.email }} con las indicaciones para iniciar tu curso, el detalle de tu compra y una copia de este comprobante.</p>
              </div>
              <div class="body">
                <div class="card-success">
                  <div class="card-success-header">
                    <h4>PAGO EXITOSO</h4>
                    <h5>{{responseTransaction.amount | moneyFormatter}}</h5>
                    <span>{{ selectedProgram.name }}</span>
                  </div>
                  <div class="item">
                    <p>Número de la tarjeta</p>
                    <span>XXXX-XXXX-XXXX-{{responseTransaction.card_detail.card_number}}</span>
                  </div>
                  <div class="item">
                    <p>No de Cuotas</p>
                    <span>{{responseTransaction.installments_number}}</span>
                  </div>
                  <div class="item dash" v-if="responseTransaction.installments_amount">
                    <p>Valor cuota</p>
                    <span>{{responseTransaction.installments_amount | moneyFormatter}}</span> 
                  </div>

                  <div class="item">
                    <p>Fecha de transación</p>
                    <span>{{responseTransaction.transaction_date | formatDate }}</span>  
                  </div>
                  <div class="item">
                    <p>Hora de transación</p>
                    <span>{{responseTransaction.transaction_date | formatDateHour }}</span>
                  </div>
                  <div class="item">
                    <p>Orden de compra</p>
                    <span>{{responseTransaction.buy_order}}</span>
                  </div>
                  <div class="item">
                    <p>Código de autorización</p>
                    <span>{{responseTransaction.authorization_code}}</span>
                  </div>
                </div>
                <button class="btn btn-volver" @click="goToHome">
                  Volver a la página de Wall Street English
                </button>
              </div>
          </div>
          <div class="animation-success"></div>
        </div>
      </div>
      <div class="loader-pay" v-if="loaderPay">
        <p>Pasarela de pago</p>
        <div class="img-pay">
          <img src="@/assets/img/pay-desktop.png" alt="img-pay" v-if="media.isDesktop" />
          <img src="@/assets/img/pay-mobile.png" alt="img-pay" v-if="!media.isDesktop" />
        </div>
        <div class="amimation-pay"></div>
        <div class="mb-5"></div>
      </div>
  
      <form :action="urlWebpay" method="POST" id="webpayForm">
        <input type="hidden" name="token_ws" :value="token"/>
      </form>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default";
export default {
  components:{
    LayoutDefault,
  },
  metaInfo() {
    return {
      title: 'Wall Street English | ' + this.getTitle(),
    };
  },
  data(){
    return {
      success: false,
      loaderPay: true,
      responseTransaction: {
        accounting_date: "1207",
        amount: 1570000,
        authorization_code: "1213",
        buy_order: "1638904181",
        card_detail: {card_number: "6623"},
        installments_amount: 523334,
        installments_number: 3,
        payment_type_code: "SI",
        response_code: 0, // -1
        session_id: "sessionId-1638904181",
        status: "AUTHORIZED", // "FAILED" //AUTHORIZED
        transaction_date: "2021-12-07T19:20:20.613Z",
        vci: "TSY"
      },
      price: 0,
      pricelist: 0,
      freeMont: 0,
      referencialPrice: 0,
      subTotal: 0,
      total: 0,
      token: '',
      urlWebpay: '',
      discount: 0
    }
  },
  mounted(){

    if(!localStorage.getItem('order')){
      this.$router.push("/")
    } else {
      this.price = this.selectedProgram.price
      this.pricelist = this.selectedProgram.pricelist
      this.subTotal = (this.selectedProgram.price + this.freeMont + this.referencialPrice)
      this.total = (this.selectedProgram.price + this.freeMont + this.referencialPrice)
      //this.commitOrder();
      /*if(this.responseTransaction.response_code == 0 && this.responseTransaction.status == 'AUTHORIZED'){
        this.success =  true
        this.loaderPay =  false
        // this.updateOrder(this.responseTransaction, true)
      } else {
        this.success =  false
        this.loaderPay =  false
        // this.updateOrder(this.responseTransaction, false)
      }*/
      this.getOrderData()
    }
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    order() {
      return this.$store.state.order
    },
    selectedProgram() {
      return this.$store.state.selectedProgram;
    },
    financingParty() {
      return this.$store.state.financingParty;
    },
    caretaker(){
      return this.$store.state.caretaker;
    },
    student(){
      return this.$store.state.student;
    }
  },
  methods:{
    getTitle(){
      if(!this.success && !this.loaderPay){
        return 'Pago Rechazado'
      } else if(this.success && !this.loaderPay){
        return 'Pago Confirmado'
      } else {
        return ''
      }
    },
    async getOrderData(){
      const buyCode = parseInt(this.$route.query.buyOrder)
      try {
        const { data } = await this.Axios.get(`/orders/byBuyCode`, {params: {
          buyOrder: buyCode
        }})
        if(data.status === 'pre-payment'){
          this.commitOrder();
        } else {
          this.responseTransaction = {
            accounting_date: data.webPayAccountingDate,
            amount: data.webPayAmount,
            authorization_code: data.webPayAuthorizationCode,
            buy_order: data.webPayBuyOrder,
            card_detail: {card_number: data.webPayCardNumber},
            installments_amount: data.webPayInstallmentsAmount,
            installments_number: data.webPayInstallmentsNumber,
            payment_type_code: data.webPayPaymentTypeCode,
            response_code: data.webPayResponseCode, // -1
            session_id: data.session_id,
            status: "AUTHORIZED", // "FAILED" //AUTHORIZED
            transaction_date: data.webPayVCI,
            vci: data.webPayVCI
          }
          this.success =  true
          this.loaderPay = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    async tryAgain(){
      this.loaderPay = true;
      try {
        const { data } = await this.Axios.post("/webpay/token", {
          buyOrder: this.order.buyOrder,
          sessionId: this.order.sessionId,
          amount: Math.round(this.order.total),
          returnUrl : `${process.env.VUE_APP_BASE_URL}payment-result`
        });
        this.token = data.token
        this.urlWebpay = data.url
        await this.Axios.put("/orders",
          {
            ...this.order, 
            tokenWs: data.token,
            sessionState: JSON.stringify(this.order.sessionState), 
          }
        ).then(async (data)=> {
          await this.$store.dispatch("setOrderInfo", data.data);
          document.getElementById("webpayForm").submit();
        });
      } catch (error) {
        this.loaderPay = false;
        console.log(error)
        alert('Error de solicitud')
      }
    },
    async commitOrder(){
      try {
        const { data } = await this.Axios.post("/webpay/commit", {
          token: this.order.tokenWs,
        });
        this.responseTransaction = data
        if(data.response_code == 0 && data.status == 'AUTHORIZED'){
          this.success =  true
          this.updateOrder(data, true)
        } else {
          this.success =  false
          this.updateOrder(data, false)
        }
        this.loaderPay = false
      } catch (error) {
        console.log(error)
        this.loaderPay = false
        this.success =  false
      }
    },
    async updateOrder(resposeT, confirmed){
      const status =  confirmed ? 'payment-confirmed' : 'payment-failed'
      try {
        const order = await this.Axios.put("/orders",
          {
            ...this.order, 
            status, 
            sessionState: JSON.stringify(this.order.sessionState),
            webPayAccountingDate: resposeT.accounting_date,
            webPayBuyOrder: resposeT.buy_order,
            webPayCardNumber: resposeT.card_detail.card_number,
            webPayDate: resposeT.transaction_date,
            webPayVCI: resposeT.vci,
            webPayAmount: resposeT.amount,
            //webPayCommerceCode: '',
            webPayAuthorizationCode: resposeT.authorization_code,
            webPayPaymentTypeCode: resposeT.payment_type_code,
            webPayResponseCode: resposeT.response_code,
            webPayInstallmentsNumber :resposeT.installments_number,
            webPayInstallmentsAmount :resposeT.installments_amount
          }
        )
        //await this.$store.dispatch("setOrderInfo", order);
        await this.Axios.post("/sendToErp", { order: order.data })
        if(confirmed){
          localStorage.clear();
          sessionStorage.removeItem('step');
          sessionStorage.removeItem('lead');
        }
      } catch (error) {
        console.log(error)
      }
    },
    goToHome(){
      localStorage.clear();
      sessionStorage.removeItem('step');
      sessionStorage.removeItem('lead');
      this.$router.push(`/`);
    }
  }
}
</script>

<style lang="scss" scoped>
  .result-pay{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .fail{
      width: 365px;
      padding: 50px 15px 50px 15px;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 100%;
      }

      .animation-fail{
        width: 100%;
        img{
          width: 100%;
        }
      }

      .title-content{
        h2{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 40px;
          line-height: 140%;
          text-align: center;
          color: #F12C3E;
          @media (max-width: 768px) {
            font-size: 30px;
            line-height: 120%
          }
        }
      }
      .text-content{
        margin-bottom: 20px;
        p{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 140%;
          text-align: center;
          color: #003359;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
      .btn-back{
        background: #003359;
        border-radius: 4px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
      }
    }

    .success{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      padding: 50px 0;

      .animation-success{
        position: absolute;
        background-image: url('~@/assets/img/success-animation.gif');
        background-size: cover;
        width: 844px;
        height: 844px;
        top: -130px;
        z-index: -1;
        @media (max-width: 768px) {
          top: -160px;
          width: 100%;
          height: 440px;
          background-position: top center;
        }
      }

      .content-success{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .header{
          width: 735px;
          margin-bottom: 10px;
          @media (max-width: 768px) {
            width: 100%;
          }
          h3{
            font-family: 'Avenir LT Pro Black';
            font-style: normal;
            font-weight: 750;
            font-size: 22px;
            line-height: 140%;
            text-align: center;
            color: #003359;
            margin-bottom: 16px;
          }
          p{
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            color: #0082A9;
            @media (max-width: 768px) {
              text-align: left;
            }
          }
        }

        .body{
          width: 355px;
          @media (max-width: 768px) {
            width: 100%;
          }
          .card-success{
            background: #FDFDFD;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .card-success-header{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              padding: 10px 0 20px;
              h4{
                font-family: 'Avenir LT Pro Light';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-transform: uppercase;
                color: #003359;
                margin: 0;
              }
              h5{
                font-family: 'Avenir LT Pro Black';
                font-style: normal;
                font-weight: 750;
                font-size: 35.5862px;
                line-height: 120%;
                color: #003359;
                margin: 0;
              }
              span{
                font-family: 'Avenir LT Pro Black';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                color: #003359;
              }
            }
            .item{
              padding: 5px 0;
              p{
                margin: 0;
                font-family: 'Avenir LT Pro Light';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                color: #808080;
              }
              span{
                font-family: 'Avenir LT Pro Light';
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 140%;
                color: #003359;
              }
            }
            .dash{
              border-bottom: 0.5px dashed #003359;
            }
          }
          .btn-volver{
            background: #003359;
            border-radius: 4px;
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 140%;
            color: #FFFFFF;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
      }
    }

    .loader-pay{
      padding: 30px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      p{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14.9415px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 14.9415px;
          line-height: 20px;
        }
      }

      .img-pay{
        width: 315px;
        @media (max-width: 768px) {
          width: 252px;
        }
        img{
          width: 100%;
        }
      }

      .amimation-pay{
        position: absolute;
        background-image: url('~@/assets/img/loading.gif');
        background-size: cover;
        width: 447px;
        height: 447px;
        z-index: -1;
        top: 0px;
        @media (max-width: 768px) {
          width: 100%;
          background-position: top center;
        }
      }
    }
  }
</style>