<template>
  <div class="banner-home-desktop">
    <div class="text-content">
      <h1 class="title">
        <b>Aprende inglés rápido con la mejor garantía</b>
      </h1>
      <div class="description" v-if="infoGeneral">
        <b>{{ infoGeneral.discountdesc }}</b>
      </div>
      <button @click="goToPlans">
        Encuentra tu modalidad ideal
      </button>
    </div>
    <div class="right-radius"></div>
    <div class="image-content"></div>
  </div>
</template>

<script>
export default {
  props:{
    infoGeneral: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      datos: ''
    }
  },
  mounted(){
    
  },
  methods:{
    goToPlans(){
      const scrollDiv = document.getElementById("plansD").offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    },
  }
}
</script>

<style lang="scss" scoped>
  .banner-home-desktop{
    width: 100%;
    display: flex;

    .text-content{
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      z-index: 2;
      padding-left: 75px;
        min-height: 476px;
      .title{
        font-family: 'Avenir LT Pro Black';
        color: #003359;
        font-size: 42px;
        line-height: 150%;
        font-weight: 700;
        margin-bottom: 15px;
        opacity: 0.8;
      }

      button {
        height: 50px;
        width: 305px;
        border-radius: 4px;
        background-color: #003359;
        color: #fff;
        font-family: 'Avenir LT Pro Light';
        font-size: 18px;
        line-height: 140%;
        border: 1px solid transparent;
      };

      .description {
        font-family: 'Avenir LT Pro Light';
        color: #404040;
        font-size: 20px;
        line-height: 140%;
        margin-bottom: 20px;
        opacity: 0.8;
      }
    }
    .right-radius{
      width: 100px;
      height: 476px;
      background-color: #fff;
      border-radius: 0 50% 50% 0;
      margin-right: -50px;
      z-index: 1;
      position: absolute;
      left: 40%;
      transform: translateX(-50%);
    }
    .image-content{
      flex: 1;
      background-image: url('~@/assets/img/home-banner-desktop.png');
      background-size: cover;
      height: 476px;
    }

  }
</style>