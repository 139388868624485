<template>
  <div class="container">
  <b-modal size="364" centered v-model="show" hide-footer hide-header  no-close-on-esc no-close-on-backdrop> 
      <div class="icon-close">
        <!--img src="@/assets/img/close-black.png" alt="close-black" 
          @click="closeModal"
        /-->
      </div>
      <div class="modal-contenido">
        <h3>Antes de continuar...</h3>
        <span>Queremos conocerte mejor para poder brindarte una experiencia más personalizada</span>
        <form @submit.prevent="sendForm" >

          <div class="row">
            <div class="col-12 col-md-12">
              <div class="form-group">
                <div class="float-container" id="container-completename" :class="{active: form.name != ''}" >
                  <label for="completename">Nombres</label>
                  <input id="completename" type="text" autocomplete="off" class="input-float" v-model="form.name" maxlength="25" 
                    onkeypress="return /[a-zA-ZáéíóúÁÉÍÓÚñÑ ]/.test(event.key)"
                  >
                </div>
                <div class="text-error" v-if="submitted && !$v.form.name.required">Este campo es requerido</div>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div class="form-group">
                <div class="float-container" id="container-lastname" :class="{active: form.lastname != ''}" >
                  <label for="lastname">Apellidos</label>
                  <input id="lastname" type="text" autocomplete="off" class="input-float" v-model="form.lastname" maxlength="25" 
                    onkeypress="return /[a-zA-ZáéíóúÁÉÍÓÚñÑ ]/.test(event.key)"
                  >
                </div>
                <div class="text-error" v-if="submitted && !$v.form.lastname.required">Este campo es requerido</div>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div class="form-group">
                <div class="float-container" id="container-emailaddress" :class="{active: form.email != ''}" >
                  <label for="emailaddress">Email</label>
                  <input id="emailaddress" type="email" autocomplete="off" class="input-float" v-model="form.email" maxlength="40">
                </div>
                <div class="text-error" v-if="submitted && !$v.form.email.required">Este campo es requerido</div>
                <div class="text-error" v-if="submitted && !$v.form.email.email">Formato de Email inválido</div>
              </div>
            </div>
            <div class="col-12 col-md-7">
              <div class="form-group">
                <div class="float-container" id="container-phonenumber" :class="{active: form.phone != ''}" >
                  <label for="phonenumber">Télefono</label>
                  <!--input id="phonenumber" type="phone" autocomplete="off" class="input-float" v-model="form.phone" -->
                  <the-mask
                    id="phonenumber"
                    class="input-float"
                    mask="+569 #### ####"
                    :masked="true"
                    v-model="form.phone"
                    type="tel"
                    name="phonenumber"
                  ></the-mask>
                </div>
                <div class="text-error" v-if="submitted && !$v.form.phone.required">Este campo es requerido</div>
                <div class="text-error" v-if="submitted && !$v.form.phone.minLength">Teléfono inválido</div>
              </div>
            </div>
          </div>

          <div class="button-content">
            <button class="btn btn-continuar" type="submit">Continuar</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
// import recaptchaVisibility from "@/mixins/recaptchaVisibility";
export default {
  components: {
    
  },
  data(){
    return{
      form: {
        name: '',
        email: '',
        phone: '',
        lastname: '',
      },
      submitted: false,
      show: true
    }
  },
  validations: {
    form:{
      name: {required},
      email: {required, email},
      phone: {required, minLength: minLength(14)},
      lastname: {required}
    }
  },
  mounted(){

  },
  computed: {
    selectedProgram() {
      return this.$store.state.selectedProgram;
    },
    students() {
      return this.$store.state.students;
    }
  },
  methods:{
    async sendForm(){
      this.submitted = true;
      await this.$recaptchaLoaded();
      this.$v.$touch()
      if(!this.$v.$invalid){
        const token = await this.$recaptcha('login')
        if (token) {
          const result = await this.Axios.post("/verify-recaptcha", {
            recaptchaToken: token
          });
          if (result.data.success) {
            this.saveFormInfo();
          }
        }
        //this.saveFormInfo()
      }
    },
    async saveFormInfo() {
      try {
        const { data } = await this.Axios.post("/leads", this.form)
        // sessionStorage.setItem('lead', JSON.stringify(data))
        this.$store.dispatch("setLeadInfo", data);
        this.$emit('continuar', data)
      } catch (e) {
        console.log(e)
      }
    },
    closeModal(){
      this.$emit('closeModal')
    }
  },
  // mixins: [recaptchaVisibility],
}
</script>

<style lang="scss" scoped>

.icon-close{
    min-height: 24px;
    display: flex;
    justify-content: flex-end;
    img{
      cursor: pointer;
    }
  }
  .modal-contenido{
    display: flex;
    flex-direction: column;
    padding: .5rem;

    h3{
      font-family: 'Avenir LT Pro Black';
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: #003359;
      opacity: 0.8;
      text-align: center;
      margin-bottom: 15px;
    }

    span{
      font-family: 'Avenir LT Pro Light';
      font-size: 16px;
      line-height: 140%;
      color: #404040;
      opacity: 0.8;
      margin-bottom: 38px;
    }

    form {
      .row{
        > .col,
        > [class*="col-"]:last-child {
          .form-group:last-child{
            margin-bottom: 38px;
          }
        }
      }

    }

    .button-content{
      display: flex;
      width:  100%;
      justify-content: center;
    }

    .btn-continuar{
      text-align: center;
      background: #003359;
      color: #fff;
      font-size: 18px;
      line-height: 140%;
      padding: 16px 29px;
    }

  }
</style>

<style lang="scss">
  .modal .modal-dialog.modal-364 {
    width: 364px !important;
    @media (max-width:768px) {
      width: auto !important;
    }
  }
</style>