<template>
  <div class="container">
    <div class="float-div" id="float-divdesktop" >
      <div class="float-div-content">
        <div class="float-div-content-img">
          <img src="@/assets/img/image-3.png" alt="logo-wst" />
        </div>
        <div class="float-div-content-texto">
          <h5 class="float-div-content-texto-red">Aprende Inglés con nuestra metodología comprobada</h5>
          <span class="float-div-content-texto-desc">
            Te lo garantizamos por escrito, <router-link to="/guarantee">Conoce nuestra garantía</router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      datos: ''
    }
  },
}
</script>

<style lang="scss" scoped>
  .float-div{
    width: 100%; 
    margin-top: -60px;
    background: #FFFFFF;
    box-shadow: 8px 8px 20px rgba(53, 55, 69, 0.08);
    border-radius: 8px;
    padding: 18px;
    .float-div-content {
      display: flex;
      height: 100%;
      width: 100%;
      .float-div-content-img{
        width: 96px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .float-div-content-texto{
        flex: 1;
        margin-left: 18px;
        display: flex;
        flex-direction: column;
        .float-div-content-texto-red{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 16px;
          line-height: 20px;
          color: #F12C3E;
        }
        .float-div-content-texto-desc{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #404040;
        }
      }
    }
  }  
</style>