<template>
  <div class="opinions" id="opinions" >
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12 content h-100">
          <h2 class="carousel-title">¿Que opinan nuestros estudiantes?</h2>
            <carousel
              :perPageCustom="[[320, 1], [768, 1], [1024, 3]]"
              paginationColor="#A6D3E1"
              paginationActiveColor="#A6D3E1"
              :navigationEnabled="false"
              :paginationPadding="2"
              :paginationSize="8"
            >
              <slide v-for="(slide, index) in sliders" :key="index">
                <div class="item">
                  <p class="item-description">{{ slide.text }}</p>
                  <div class="item-avatar">
                    <div class="avatar">
                      <img :src="require(`@/assets/img/${slide.avatar}`)" :alt="slide.text" />
                    </div>
                    <div class="item-avatar-text">
                      <h5 class="item-avatar-text-title">{{ slide.name }}</h5>
                      <span class="item-avatar-text-desc">{{ slide.description }}</span>
                    </div>
                  </div>
                </div>
                </slide>
            </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Carousel, Slide } from 'vue-carousel'
export default {
   components:{
    Carousel,
    Slide,
  },
  data(){
    return {
      sliders: []
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}opinionsData.json`)
        this.sliders = data;
      } catch (error) {
        console.log(error)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.opinions{
  background-image: url('~@/assets/svg/Burbujas-desktop.svg');
  background-size: cover;
  height: 610px;

  @media (max-width: 768px) {
    background-image: url('~@/assets/svg/Burbujas.svg');
    height: 402px;
  }

  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .h-100{
    height: 100%;
  }

  .carousel-title{
    font-family: 'Avenir LT Pro Black';
    font-style: normal;
    font-weight: 750;
    font-size: 35px;
    line-height: 140%;
    text-align: center;
    color: #003359;
    opacity: 0.8;
    margin-bottom: 50px ;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 120%;
    }
  }

  .item{
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    padding: 15px 19px;
    @media (min-width: 768px) {
      margin: 0 10px;
    }

    .item-description{
      font-family: 'Avenir LT Pro Light';
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #404040;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .item-avatar{
      display: flex;
      .avatar{
        width: 36px;
        margin-right: 15px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .item-avatar-text{
        display: flex;
        flex-direction: column;
        .item-avatar-text-title{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #404040;
          margin: 0;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        .item-avatar-text-desc{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #808080;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      } 
    }
  }

}

</style>

<style>
  .VueCarousel{
    width: 100%;
  }
</style>