<template>
  <layout-default>
    <div class="garantee wse-page">
      <div class="banner">

      </div>
      <div class="content-garantee mb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="title-garantee mt-5">
                CONDICIONES DE GARANTÍA
              </h1>

              <h2 class="subtitle-garantee">
                1. Debido seguimiento del método Wall Street English:
              </h2>
              <p class="text-garantee">
                Para poder garantizar el aprendizaje del inglés, es necesario que el alumno cumpla a cabalidad con el Método Exclusivo Wall Street English, el cual consta en lo siguiente: 
              </p>
              <ol>
                <li><b>Aprendizaje individual asistido</b> en un Centro de Lenguaje y/o plataforma English Enytime (Interactive Lesson): El alumno, cada vez que acceda a sus lecciones en el Centro de Lenguaje o a través de English Anytime, deberá finalizar una lección por visita en cualquiera de estas dos plataformas.</li>
                <li><b>Manual de Estudio (Libro o Libro Digital):</b>  Posteriormente, deberá desarrollar los ejercicios de la lección respectiva en el manual de estudios</li>
                <li><b>Clases con el profesor (encounters):</b>  Para finalizar su ciclo de adquisición, deberá realizar al menos dos enoncunters mensuales.</li>
                <li><b>Complementary Classes (Social Clubs y otras actividades):</b> Regularmente, en los Centros de Wall Street English y/o en lugares públicos se realizan actividades complementarias, a las cuales el alumno deberá asistir para reforzar su</li>
                <li><b>Estudio Personal:</b>  El alumno tiene derecho, durante la duración de su curso –indicada en su contrato-, a estudiar con el método todas las horas multimedia que necesite a través de la paltaforma English Anytime, previamente indicadas por el Service Manager del Centro hasta alcanzar el nivel de inglés Para que la presente garantía sea válida, el alumno deberá haber estudiado 4 o más horas por semana en la fase del ciclo de adquisición multimedia.</li>
                <li><b>Core Course:</b>  El sistema de evaluación deWall Street English se denomina Core Course, y dispone a lo largo del curso, de un sistema constante de autoevaluación objetiva del aprendizaje al finalizar cada nivel (Level check point). Los resultados de estas evaluaciones pueden ser revisados por el propio alumno con el fin de verificar su avance*.</li>
              </ol>
              <h2 class="subtitle-garantee">2.  Otras condiciones de Garantía:</h2>
              <p class="text-garantee">Para hacer efectiva la garantía Wall Street English, debe haber finalizado el periodo del curso indicado en el contrato, habiendo el alumno cumplido con todas las condiciones indicadas en el número 1, letras A a la F, respecto del Método Exclusivo Wall Street English, sin haber alcanzado el Level check point Si esto es así, percibirá un mes adicional por cada nivel no aprobado de su contrato para ayudarlo a lograr el objetivo al que hace referencia la presente garantía.</p>
              <p class="text-garantee">Si habiéndose entregado los meses adicionales a los que hace referencia la letra a anterior, y habiendo cumplido el alumno durante esos meses adicionales el Método Exclusivo Wall Street English a cabalidad de acuerdo a las letras a la f del número 1 de la presente garantía, aún así no hubiera alcanzado el Level check point correspondiente, se realizará el reembolso del importe total que hubiese pagado por cada nivel no Nominativo e intransferible.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default";
export default {
  components:{
    LayoutDefault
  },
  metaInfo: {
    title: 'Wall Street English',
    titleTemplate: '%s | Garantía'
  },
}
</script>

<style lang="scss" scoped>

.title-garantee{
  font-family: 'Avenir LT Pro Black';
  color: #003359;
  font-size: 36px;
  line-height: 150%;
  font-weight: 700;
  margin-bottom: 15px;
  opacity: 0.8;
}

.subtitle-garantee{
  font-family: 'Avenir LT Pro Black';
  font-style: normal;
  font-weight: 750;
  font-size: 24px;
  line-height: 140%;
  color: #003359;
  opacity: 0.8;
}

p, li{
  font-family: 'Avenir LT Pro Light';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: #003359;
}

ol {
  list-style-type: upper-alpha;
}

</style>