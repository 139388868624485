<template>
  <div class="resumen">
    <div class="resumen-header" v-if="media.isDesktop" >
      <span class="span-top">Tu Producto</span>
      <div class="separate">
        <div class="name">
          <p>{{selectedProgram.name}}</p>
        </div>
        <div class="price">
          <p class="price-value">{{selectedProgram.price | moneyFormatter}}</p>
          <span class="price-discount">{{selectedProgram.pricelist | moneyFormatter}}</span>
        </div>
      </div>
    </div>
    <div class="resumen-location" v-if="media.isDesktop" >
      <span>Sede</span>
      <p>{{ selectedLocation.name }}</p>
    </div>
    <div class="resumen-content" v-if="media.isDesktop" >
      <p>Incluye:</p>
      <div class="featured" v-for="(detail, k) in selectedProgram.Details" :key="k" >
        <div class="check">
          <img src="@/assets/svg/check_white_16.svg" alt="check" />
        </div>
        <p>
          {{ detail.ProgramDetail.value }} {{ detail.name }}
        </p>
      </div>
    </div>

    <div class="alumno" v-if="alumno">
      <div class="resumen-header-alumno" v-if="media.isDesktop" v-b-toggle="'collapse-alumno'">
        <div class="toggle-plan-alumno">
          <span>Tus Datos Personales</span>
          <img src="@/assets/svg/withe-collapse-arrow.svg" alt="withe-collapse-arrow"  class="when-closed" width="18" height="18" />
          <img src="@/assets/svg/withe-collapse-arrow.svg" alt="withe-collapse-arrow"  class="when-opened" width="18" height="18"  />
        </div>
      </div>
      <b-collapse id="collapse-alumno" v-if="media.isDesktop">
        <div class="resumen-content-alumno"> 
          <h5>Datos del Apoderado</h5>

          <div class="item-data">
            <span>Nombres</span> 
            <p>{{ financingParty.name  }} {{ financingParty.lastName }} </p>
            <p>{{ financingParty.rut  }}</p>  
          </div>
          <div class="item-data">
            <span>Direccion</span> 
            <p>{{ financingParty.address  }} {{ financingParty.address2 }} </p>
          </div>
          <div class="item-data">
            <span>Email</span> 
            <p>{{ financingParty.email  }}</p>
          </div>
          <div class="item-data">
            <span>Teléfono</span> 
            <p>{{ financingParty.phone  }}</p>
          </div>
          
          <div class="border-line"></div>

          <h5>Datos del Alumno</h5>

          <div class="item-data">
            <span>Nombres</span> 
            <p>{{ student.name  }} {{ student.lastName }} </p>
            <p>{{ student.rut  }}</p>  
          </div>

          <div class="item-data">
            <span>Direccion</span> 
            <p>{{ student.address  }} {{ student.address2 }} </p>
          </div>

          <div class="item-data">
            <span>Email</span> 
            <p>{{ student.email  }}</p>
          </div>

          <div class="item-data">
            <span>Teléfono</span> 
            <p>{{ student.phone  }}</p>
          </div>
        
        </div>
      </b-collapse>
    </div>
    

    <div class="resumen-header" v-if="!media.isDesktop" v-b-toggle="'collapse-plan'"  >  
      <div class="toggle-plan">
        <span>Tu Producto</span>
        <img src="@/assets/svg/withe-collapse-arrow.svg" alt="withe-collapse-arrow"  class="when-closed" width="18" height="18" />
        <img src="@/assets/svg/withe-collapse-arrow.svg" alt="withe-collapse-arrow"  class="when-opened" width="18" height="18"  />
      </div>
      <div class="separate">
        <div class="name">
          <p>{{selectedProgram.Metas.name}}</p>
        </div>
        <div class="price">
          <p class="price-value">{{selectedProgram.price | moneyFormatter}}</p>
          <span class="price-discount">{{selectedProgram.pricelist | moneyFormatter}}</span>
        </div>
      </div>
    </div>
    <b-collapse id="collapse-plan" v-if="!media.isDesktop">  
      <div class="resumen-content">
        <p>Incluye:</p>
        <div class="featured" v-for="(detail, k) in selectedProgram.Details" :key="k" >
          <div class="check">
            <img src="@/assets/svg/check_white_16.svg" alt="check" />
          </div>
          <p>
            {{ detail.ProgramDetail.value }} {{ detail.name }}
          </p>
        </div>
      </div>
    </b-collapse>
    <div class="resumen-location" v-if="!media.isDesktop">
      <span>Sede</span>
      <p>{{ selectedLocation.name }}</p>
    </div>
    <div class="alumno" v-if="!media.isDesktop && alumno">
      <div class="resumen-header-alumno"  v-b-toggle="'collapse-alumno'">
        <div class="toggle-plan-alumno">
          <span>Tus Datos Personales</span>
          <img src="@/assets/svg/withe-collapse-arrow.svg" alt="withe-collapse-arrow"  class="when-closed" width="18" height="18" />
          <img src="@/assets/svg/withe-collapse-arrow.svg" alt="withe-collapse-arrow"  class="when-opened" width="18" height="18"  />
        </div>
      </div>
      <b-collapse id="collapse-alumno">
        <div class="resumen-content-alumno"> 
          <h5>Datos del Apoderado</h5>

          <div class="item-data">
            <span>Nombres</span> 
            <p>{{ financingParty.name  }} {{ financingParty.lastName }} </p>
            <p>{{ financingParty.rut  }}</p>  
          </div>
          <div class="item-data">
            <span>Direccion</span> 
            <p>{{ financingParty.address  }} {{ financingParty.address2 }} </p>
          </div>
          <div class="item-data">
            <span>Email</span> 
            <p>{{ financingParty.email  }}</p>
          </div>
          <div class="item-data">
            <span>Teléfono</span> 
            <p>{{ financingParty.phone  }}</p>
          </div>
          
          <div class="border-line"></div>

          <h5>Datos del Alumno</h5>

          <div class="item-data">
            <span>Nombres</span> 
            <p>{{ student.name  }} {{ student.lastName }} </p>
            <p>{{ student.rut  }}</p>  
          </div>

          <div class="item-data">
            <span>Direccion</span> 
            <p>{{ student.address  }} {{ student.address2 }} </p>
          </div>

          <div class="item-data">
            <span>Email</span> 
            <p>{{ student.email  }}</p>
          </div>

          <div class="item-data">
            <span>Teléfono</span> 
            <p>{{ student.phone  }}</p>
          </div>
        
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alumno: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      datos: ''
    }
  },
  computed: {
    selectedProgram() {
      return this.$store.state.selectedProgram;
    },
    selectedLocation() {
      return this.$store.state.selectedLocation;
    },
    caretaker(){
      return this.$store.state.caretaker;
    },
    student(){
      return this.$store.state.students[0];
    },
    financingParty(){
      return this.$store.state.financingParty;
    },
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  },
}
</script>

<style lang="scss" scoped>

  .when-opened{
    transform: rotate(180deg);
  }
  .collapsed > .toggle-plan > .when-opened,
  .not-collapsed > .toggle-plan > .when-closed {
    display: none !important;
  }
  .collapsed > .opened,
  .not-collapsed > .closed {
    display: none !important;
  }

  .collapsed > .toggle-plan-alumno > .when-opened,
  .not-collapsed > .toggle-plan-alumno > .when-closed {
    display: none !important;
  }

  .resumen{
    background: #F5616E;
    border-radius: 8px;
    width: 335px;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
    }
    .resumen-header{
      padding: 15px;
      .toggle-plan{
        display: flex;
        justify-content: space-between;
      }
      span{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .separate{
        display: flex;
        .name{
          flex: 1;
          p{
            font-family: 'Avenir LT Pro Black';
            font-style: normal;
            font-weight: 750;
            font-size: 18px;
            line-height: 140%;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            margin: 0;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
        .price{
          margin-left: 10px;
          .price-value{
            margin: 0;
            font-family: 'Avenir LT Pro Black';
            font-style: normal;
            font-weight: 750;
            font-size: 18px;
            line-height: 140%;
            display: flex;
            align-items: center;
            justify-content: right;
            color: #FFFFFF;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
          .price-discount{
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            display: flex;
            align-items: center;
            justify-content: right;
            text-decoration-line: line-through;
            color: #FFFFFF;
          }
        }
      }
    }
    .resumen-location{
      padding: 15px;
      border-top: 1px solid #F97A85;
      border-bottom: 1px solid #F97A85;
      @media (max-width: 768px) {
        border-bottom: none;
      }
      span{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;
      }
      p{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
        margin: 0;
      }
    }
    .resumen-content{
      padding: 15px;
      p{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;
        margin-bottom: .8rem;
      }
      .featured{
        display: flex;
        .check{
          width: 16px;
          margin-right: 10px;
          img{
            width: 100%;
          }
        }
        p{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          color: #FFFFFF;
          margin-bottom: .8rem;
        }
      }
    }
  }


  .alumno{
    background: #0082A9;
    border-radius: 0px 0px 8px 8px;

    .resumen-header-alumno{
      padding: 15px;
      .toggle-plan-alumno{
        display: flex;
        justify-content: space-between;
      }
      span{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .resumen-content-alumno {
      border-top: 1px solid #A6D3E1;
      background: rgba(241, 44, 62, 0.2);
      padding: 15px;
      h5{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 140%;
        color: #fff;
      }
      .item-data{
        margin-bottom: .5rem;
        span{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 140%;
          color: #FFFFFF;
          opacity: 0.7;
        }
        p{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 140%;
          color: #FFFFFF;
          margin-bottom: .2rem;
        }
      }
      .border-line{
        border: 1px solid #A6D3E1 !important;
        margin: 10px 0px;
      }
    }
  }

  
</style>