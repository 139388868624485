<template>
 <layout-default>
  <div class="page-checkout wse-page">
    <div class="header-step" v-if="step != 3">
      <div class="container h-100">
        <div class="flex h-100">
          <div class="flex-1">
            <h5 class="title">Checkout</h5>
          </div>
          <div class="flex-1">
            <div class="wizard">
              <div class="wizard-content">
                <div class="step step-1" :class="{ 'step-active': step == 1, 'step-complete': step == 2 }">
                  <span v-if="step == 1">1</span>
                  <img src="@/assets/img/check-white.svg" alt="check-white" v-if="step > 1" />
                </div>
                <!--div class="step step-2" :class="{ 'step-active': step == 2 }">2</div-->
                <div class="step step-2" :class="{ 'step-active': step == 2, 'step-complete': step == 3 }">
                  <span v-if="step <= 2">2</span>
                  <img src="@/assets/img/check-white.svg" alt="check-white" v-if="step > 2" />
                </div>
                <div class="step step-3" :class="{ 'step-active': step == 3 }" >
                  <span>3</span>
                </div>
                <div class="bar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-content">
      <div class="container">
        <Step1 v-if="step === 1" @next="nextStep" />
        <Step2 v-if="step === 2" @next="nextStep" />
        <Step3 v-if="step === 3" />
      </div>
    </div>
  </div>
 </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default";
import Step1 from "@/components/checkout/step-1";
import Step2 from "@/components/checkout/step-2";
import Step3 from "@/components/checkout/step-3";
export default {
  components:{
    LayoutDefault,
    Step1,
    Step2,
    Step3
  },
  metaInfo: {
    title: 'Wall Street English',
    titleTemplate: '%s | Checkout'
  },
  data(){
    return {
      step:  JSON.parse(sessionStorage.getItem('step')) || 1,
      loader: false
    }
  },
  mounted(){
    if(!localStorage.getItem('selectedLocation')){
      this.$router.push("/")
    } else {
      if(!localStorage.getItem('order')){
        localStorage.removeItem('students')
        localStorage.removeItem('financingParty')
        localStorage.removeItem('caretaker')
        localStorage.removeItem('fullOrder')
        localStorage.removeItem('order')
        localStorage.removeItem('ordenID')
        localStorage.removeItem('lastTime')
        sessionStorage.removeItem('step')
      }
    }
  },
  methods:{
    nextStep(value){
      this.step = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-checkout{
    width: 100%;
    .header-step{
      height: 121px;
      background: #C9EEF7;

      .title{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 24px;
        line-height: 140%;
        color: #003359;
      }

      .flex{
        display: flex;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
      .flex-1{
        display: flex;
        flex: 1;
        align-items: center;
      }

      .h-100{
        height: 100%;
      }
      .wizard{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        .wizard-content{
          width: 333px;
          position: relative;
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            width: 100%;
          }
          .bar{
            height: 1px;
            background: #A6B8C5;
            width: 100%;
          }
          .step{
            width: 28px;
            height: 28px;
            background: #A6B8C5;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 0;
            color: #FFFFFF;
            cursor: pointer;
            padding: 0;
            span{
              font-size: 18px;
              margin: 0;
            }
          }
          .step-active{
            background: #64CFE9 !important;
          }
          .step-1{
            position: absolute;
            left: -7px;
            @media (max-width: 768px) {
              left: 0px;
            }
          }
          .step-complete{
            background: #003359 !important
          }
          .step-2{
            position: absolute;
            left: 152.5px;
            @media (max-width: 768px) {
              left: calc(50% - 14px);
            }
          }
          .step-3{
            position: absolute;
            right: 0px;
          }
        }
      }
    }
    .checkout-content{
      padding: 70px 0;
    }
  }
</style>