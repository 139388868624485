<template>
  <div class="detail" v-if="modality">
    <div class="container">
      <div class="detail-title">
        <h2>¿Qué incluye el programa {{ modality.name }}?</h2>
        <!-- <location v-for="elemento in [1,2,3]" :key="elemento"/> -->
        
      </div>
      <locations/>
      <div class="detail-featured">
        <div class="row">
          <div class="col-12 col-md-6 vertical-center">
            <div class="accordion" role="tablist">
              <div class="accordion-item" role="tablist" v-for="(featured, i) in modality.featureds" :key="i" >
                <div class="featured-title-content" role="tablist" v-b-toggle="'accordion-' + i"  >
                  <div class="check-content">
                    <img src="@/assets/img/check_blue.svg" alt="check_blue" class="check" />
                  </div>
                  <span class="accordion-item-title closed">{{ featured.title }}</span>
                  <span class="accordion-item-title opened b">{{ featured.title }}</span>
                  <img src="@/assets/img/keyboard_arrow_down.svg" alt="keyboard_arrow_down"  class="arrow when-closed" width="24" height="24" />
                  <img src="@/assets/img/keyboard_arrow_up.svg" alt="keyboard_arrow_up"  class="arrow when-opened" width="24" height="24"  />
                </div>
                <b-collapse :id="'accordion-' + i" visible accordion="my-accordion" role="tabpanel">
                  <div class="featured-description-content" role="tablist">
                    <p>{{ featured.text }}</p>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" v-if="media.isDesktop">
            <div class="detail-main-image">
              <img :src="require(`@/assets/img/${modality.mainImage}`)" alt="main-image" />
            </div>
          </div>
        </div>
      </div>
      <div class="detail-general-info">
        <div class="detail-general-info-wrapper">
          <h4>{{ modality.title }}</h4>
          <h5>{{ modality.subtitle }}</h5>
          <p>{{ modality.text }}</p>
        </div>
      </div>
      <div class="detail-metodologies" v-if="media.isDesktop">
        <div class="row">
          <div class="col-12 col-md-3" v-for="(medodology, i) in modality.metodologies" :key="i"  >
            <div class="item-metodology">
              <div class="item-metodology-image">
                <img :src="require(`@/assets/img/${medodology.icon}`)" alt="main-image" />
              </div>
              <p>{{ medodology.text }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-detail" v-if="!media.isDesktop" >
        <carousel
          :perPage="1"
          paginationColor="#A6D3E1"
          paginationActiveColor="#A6D3E1"
          :navigationEnabled="true"
          :paginationPadding="2"
          :navigation-next-label="navigationNext" 
          :navigation-prev-label="navigationPrev"
          :paginationSize="8"
        >
          <slide v-for="(slide, index) in modality.metodologies" :key="index">
            <div class="item">
              <div class="item-image">
                <img :src="require(`@/assets/img/${slide.icon}`)" :alt="slide.subtitle" />
              </div>
              <div class="item-content">
                <p class="item-description">{{ slide.text }}</p>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel'
  import ChevronRight from '@/assets/img/right-arrow.svg';
  import ChevronLeft from '@/assets/img/left-arrow.svg';
 
export default {
  components:{
    Carousel, Slide,
  },
  props:{
    modality: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      datos: ''
    }
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    navigationNext() {
      const chevronRight = ChevronRight;
      return `<img src="${chevronRight}"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      return `<img src="${chevronLeft}"/>`;
    },
  },
}
</script>

<style lang="scss" scoped>

  .vertical-center{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .detail-title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    @media (max-width: 768px) {
      padding: 60px 20px
    }
    h2{
      font-family: 'Avenir LT Pro Black';
      font-style: normal;
      font-weight: 750;
      font-size: 35px;
      line-height: 140%;
      text-align: center;
      color: #003359;
      opacity: 0.8;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 120%;
      }
    }
  }
  .accordion{
    .accordion-item{
      width: 100%;
      .featured-title-content{
        width: 100%;
        display: flex;
        padding: 15px 0;
        cursor: pointer;
        .check-content{
          margin-right: 10px;
          img{
            width: 100%;
          }
        }
        .accordion-item-title{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 140%;
          color: #003359;
          flex: 1;
          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
        .arrow-content{
          width: 24px;
          img{
            width: 100%;
          }
        }
      }
      .featured-description-content{
        p{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 140%;
          color: #404040;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .collapsed > .when-opened,
  .not-collapsed > .when-closed {
    display: none;
  }
  .collapsed > .opened,
  .not-collapsed > .closed {
    display: none;
  }
  .b{
    font-family: 'Avenir LT Pro Black' !important;
    font-weight: 750 !important;
  }
  .detail-general-info{
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .detail-general-info-wrapper{
      width: 555px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
      }
      h4{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #003359;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      h5{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 35px;
        line-height: 140%;
        text-align: center;
        color: #003359;
        opacity: 0.8;
        margin-bottom: 30px !important;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 120%;
          margin: 0 15% 30px 15%;
        }
      }
      p{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #003359;
        opacity: 0.8;
        @media (max-width: 768px) {
          font-size: 16px;
          text-align: justify;
        }
      }
    }
  }
  .detail-metodologies{
    padding: 0px 0px 80px 0;
    .item-metodology{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .item-metodology-image{
        width: 80px;
        margin-bottom: 20px;
        img{
          width: 100%;
        }
      }
      p{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #404040;
        opacity: 0.8;
      }
    }
  }

  .carousel-detail{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
  }
  .item{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item-image{
      width: 80px;
      margin-bottom: 20px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .item-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .item-description{
        text-align: center;
        color: #404040;
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        margin: 0;
      }
    }
  }
  .VueCarousel-navigation-prev{
    left: 20px !important;
  }
  .VueCarousel-navigation-next{
    right: 20px !important;
  }
</style>

<style>
   .VueCarousel-navigation-prev{
    left: 20px !important;
    margin-top: -30px !important;
  }
  .VueCarousel-navigation-next{
    right: 20px !important;
    margin-top: -30px !important;
  }
  .VueCarousel-pagination{
    margin-top: 10px !important;
  }
  .VueCarousel-dot--active{
    width: 24px !important;
    border-radius: 8px !important;
  }
</style>