<template>
  <div class="modality-banner">
    <img src="@/assets/img/fondo-modality-detail.png" alt="modality-banner" v-if="media.isDesktop" />
    <img src="@/assets/img/fondo-modality-mobile.png" alt="modality-banner" v-if="!media.isDesktop" />
    <div class="text-content" v-if="modality">
      <div class="wrapper-text">
        <h1 v-if="studenName">¡Hola {{ studenName }}!</h1>
        <p>Comienza tu viaje de aprendizaje ahora con {{ modality.name }}</p>
        <a href="#programs" class="btn btn-planes">Conocer planes</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    modality: {
      type: Object,
      default: null
    },
  
  },
  data(){
    return{
      modaly: ''
    }
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    studenName() {
      if(this.$store.state.lead){
        const name = this.$store.state.lead.name;
        return name.trim();
      }
      return ''
    },
  },
  methods: {
    studenName1() {
      if(this.$store.state.lead){
        const name = this.$store.state.lead.name;
        return name.trim();
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
  .modality-banner{
    background: rgba(100, 207, 233, 0.5);
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    .text-content{
      background: rgba(100, 207, 233, 0.5);
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .wrapper-text{
        width: 434px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      h1{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 40px;
        line-height: 140%;
        text-align: center;
        color: #003359;
        opacity: 0.8;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 120%;
        }
      }
      p{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #003359;
        opacity: 0.8;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
      .btn.btn-planes{
        padding: 0 12px;
      }
      .btn-planes{
        background: #003359;
        border-radius: 4px !important;
        color: #fff !important;
        width: 172px !important;
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 50px;
      }
    }
  }
</style>