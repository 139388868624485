<template>
  <div class="select-box">
    <div class="select" @click="triggerSelect">
      <span>{{ name }}</span>
      <img src="@/assets/img/arrow_drop_down.png" alt="arrow_drop_down" v-if="!openSelect" />
      <img src="@/assets/img/arrow_drop_up.png" alt="arrow_drop_up" v-if="openSelect"  />
    </div>
    <div class="select-options" v-if="openSelect">
      <template v-for="(location, l) in locations">
        <span :key="'reg-'+ l" class="region">
          {{ location.region }}
        </span>
        <span  v-for="(item, loc) in location.locations" @click="changeLocation(item)" 
          :key="'loc-'+ loc + '' + item.name" 
          class="location" 
        >
          {{ item.name }}
        </span>
      </template>
    </div>
    <b-modal modal-class="modal-fullscreen" v-model="openSelect" hide-footer hide-header v-if="!media.isDesktop" > 
      <div class="icon-close">
        <img src="@/assets/img/close-black.png" alt="close-black" 
          @click="openSelect= false"
        />
      </div>
      <div class="modal-contenido">
        <template v-for="(location, l) in locations">
          <span :key="'reg-'+ l" class="region">
            {{ location.region }}
          </span>
          <p  v-for="(item, loc) in location.locations" @click="changeLocation(item)" 
            :key="'loc-'+ loc + '' + item.name" 
            class="location" 
          >
            {{ item.name }}
          </p>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props:{
    locations: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{
      name: 'Seleccione una sede',
      sede: null,
      openSelect: false
    }
  },
  mounted(){
    
  },
  methods:{
    changeLocation(location){
      this.$emit('selected', location)
      this.sede = location
      if(location){
        this.name = location.name
      } else {
        this.name = 'Seleccione una sede'
      }
      this.openSelect = false
    },
    triggerSelect(){
      this.openSelect =  !this.openSelect
    }
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  }
}
</script>

<style lang="scss" scoped>

  .select-box{
    position: relative;
  }

  .select{
    background: transparent;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #0082A9;
    cursor: pointer;
    
    span{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      color: #406683;
    }
  }

  .select-options{
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #0082A9;
    background: #fff;
    padding: 15px;
    margin-top: 4px;
    z-index: 2;
    height: 250px;

    /*overflow-x: hidden;*/
    overflow-y: scroll;
    position: absolute;
    right: 0px;
    left: 0px;
   
    @media (max-width: 768px) {
      display: none;
    }

    .region{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #0082A9;
    }

    .location{
      cursor: pointer;
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: #404040;
      margin-bottom: 10px;
    }
  }

  .select-options::-webkit-scrollbar {
    width: 5px;
    background: #f5f5f5;
  }

  .select-options::-webkit-scrollbar-thumb {
    background: #a6b8c5;
    border-radius: 5px;
  }

  .icon-close{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    img{
      cursor: pointer;
    }
  }

  .region{
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #0082A9;
  }

  .location{
    cursor: pointer;
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #404040;
    margin-bottom: 20px;
  }

  
</style>

<style lang="scss">
  .modal-fullscreen .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
  }
</style>