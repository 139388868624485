<template>
  <div>
  <b-tabs  class="tabs-title" content-class="mt-3">
      <b-tab title="TEENS 100% ONLINE FULL" :active="isActive">
    <div class="carousel-programs" v-if="media.isDesktop" >
          <carousel
            :perPage="3"
            paginationColor="#A6D3E1"
            paginationActiveColor="#A6D3E1"
            :navigationEnabled="true"
            :paginationPadding="2"
            :navigation-next-label="navigationNext" 
            :navigation-prev-label="navigationPrev"
            :paginationSize="8"
          >
            <slide v-for="(program, index) in programsFull" :key="index">
              <div class="item" >   
                <ItemProgram :program="program" />
              </div>
            </slide>
          </carousel>
        </div>
        <div class="content-mobile" v-if="!media.isDesktop" >
          <div class="item" v-for="program in programsFull" :key="program.id">
            <ItemProgram :program="program" />
          </div>
        </div>
      </b-tab>
      <b-tab title="TEENS 100% ONLINE BASIC" :active="notActive">
    <div  class="carousel-programs" v-if="media.isDesktop"  >
          <carousel
            :perPage="3"
            paginationColor="#A6D3E1"
            paginationActiveColor="#A6D3E1"
            :navigationEnabled="true"
            :paginationPadding="2"
            :navigation-next-label="navigationNext" 
            :navigation-prev-label="navigationPrev"
            :paginationSize="8"
          >
            <slide v-for="(program, index) in programsBasic" :key="index">
              <div class="item" >   
                <ItemProgram :program="program" />
              </div>
            </slide>
          </carousel>
        </div>
        <div class="content-mobile" v-if="!media.isDesktop" >
          <div class="item" v-for="program in programsBasic" :key="program.id">
            <ItemProgram :program="program" />
          </div>
        </div>
      </b-tab> 
  </b-tabs>
</div>
</template>

<script>
  
  import { Carousel, Slide } from 'vue-carousel'
  import ItemProgram from "@/components/modalities/ItemProgram";
  import ChevronRight from '@/assets/img/right-arrow.svg';
  import ChevronLeft from '@/assets/img/left-arrow.svg';
 
export default {
  components:{
    Carousel, Slide, ItemProgram,
  },
  props:{
    programsFull:{
      type:Array,
      default:()=>[]
    },
    programsBasic:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return{
      datos: null,
      fullprograms:[],
      basicprograms:[],
      isActive:true,
      notActive:false,
    }
  },


  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    navigationNext() {
      const chevronRight = ChevronRight;
      return `<img src="${chevronRight}" class="w50 h50 custom-arrow"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      return `<img src="${chevronLeft}" class="w50 h50 custom-arrow" />`;
    },

  },
  mounted(){
    setTimeout(() => {
      console.log("flag 1",this.programs)  
      this.notActive=true
    },2000);
    setTimeout(() => {
      console.log("flag 1",this.programs)  
      this.notActive=false
      this.isActive=true
    },2100);
  },
}



</script>

<style lang="scss" scoped>
  .programs{
    margin-bottom: 80px;
    position: relative;
    .programs-title{
      padding: 80px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: 768px) {
        padding: 50px 40px;
      }
      h3{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #003359;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      h4{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 35px;
        line-height: 140%;
        text-align: center;
        color: #003359;
        opacity: 0.8;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 120%;
        }
      }
    }
    .item{
      padding: 10px;
    }
  }
  .content-mobile{
    width: 100%;
    .item{
      width: 100%;
      margin-bottom: 10px;
    }
  }
</style>

<style lang="scss">
#programs {
  .programs .VueCarousel-wrapper{
    overflow: unset;
    overflow-x: clip;

    z-index: 2;
  }
  .VueCarousel-navigation-prev{
    padding: 20px !important;
  }
  .VueCarousel-navigation-next{
    padding: 20px !important;
  } 
  .VueCarousel-dot{
    background-color: rgb(245, 97, 110) !important;
  }
  .VueCarousel-dot--active{
   background-color: rgb(245, 97, 110) !important;
  }
  .w50{
    width: 50px;
  }
  .h50{
    height: 50px;
  }
  .custom-arrow{
    background: #F5F5F5;
  }

  .accordion-sedes{
  color: rgba(1,51,89,255);
  font-family: 'Avenir LT Pro Light';
  font-size: 20px;
}
.sedes-title{
  color: rgba(1,51,89,255);
  font-weight: bold;
  font-family: 'Avenir LT Pro Black';
  text-align: center;
  padding: 1.5rem;

}
.sedes-subtitle{
  color: rgba(1,51,89,255);
  font-weight: bold;
  font-family: 'Avenir LT Pro Black';
}

.accordion-title{
  font-weight: bold;
}
.tabs-title{
  font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
}

}
</style>
