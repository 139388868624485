<template>
  <div class="home-banner-container">
    <div class="hero-banner">
      <div class="hero-img-container">
        <img src="@/assets/img/banner-mobile.png" alt="wse-logo-mobile">
        <div class="float-logo">
          <img src="@/assets/img/logo-only.png" alt="wse-logo-mobile">
        </div>
      </div>
      <div class="hero-circle"></div>
      <div class="hero-content">
        <div class="hero-info">
          <h1 class="title">
            <b>Aprende inglés rápido con la mejor garantía</b>
          </h1>
          <div class="description" v-if="infoGeneral">
            <b>{{ infoGeneral.discountdesc }}</b>
          </div>
          <button @click="goToPlans">
            Encuentra tu modalidad ideal
          </button>
        </div>
      </div>
    </div>
 </div>
</template>

<script>
export default {
  props:{
    infoGeneral: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      datos: ''
    }
  },
  methods:{
    goToPlans(){
      const scrollDiv = document.getElementById("plansM").offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss" scoped>

.home-banner-container{
  width: 100%;
  margin-bottom: 30px;
    @media (max-width: 768px){
        margin-bottom: 37px;
    }
  .hero-banner{
    display: flex;
    flex-direction: column;
    .hero-img-container{
      width: 100%;
      position: relative;
      img{
        width: 100%;
        height: auto;
      }

      .float-logo{
        position: absolute;
        width: 80px;
        height: 80px;
        right: 18px;
        top: 18px;
        border-radius: 56px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .hero-circle{
      height: 100px;
      width: 100%;
      border-radius: 50%;
      margin-top: -50px;
      background-color: #fff;
      z-index: 2;
    }
    .hero-content {
      margin-top: -55px;
      width: 100%;
      background-color: #fff;
      z-index: 3;
  
      .hero-info{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 30px;

        .title{
          font-family: 'Avenir LT Pro Black';
          color: #003359;
          font-size: 24px !important;
          line-height: 120% !important;
          font-weight: bold;
          text-align: center;
          margin-bottom: 15px;
        }

        button {
          height: 50px;
          border-radius: 4px;
          background-color: #003359;
          color: #fff;
          font-family: 'Avenir LT Pro Light';
          font-size: 18px;
          border: 1px solid transparent;
          width: 100%;
        };

        .description {
          font-family: 'Avenir LT Pro Light';
          color: #404040;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 20px;
        }
      }

    }
  }
}

</style>