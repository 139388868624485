import Vue from 'vue';
import moment from 'moment'
import Vuex from 'vuex';
import {TIME_LOCAL_PAGE_TIMER,TIME_EXPIRED_PAGE_TIMER} from '../constants'
import router from '../router';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const defaultData = {
    //data perteneciente al flujo completo que se maneja con localstorage
    students: [],
    student:{
        caretakerId: null,
        financingPartyId: null,
        name: null,
        lastName: null,
        rut: null,
        email: null,
        phone: null,
        commune: null,
        address: null,
        address2: null,
        address3: null,
        establishment: null,
        graduationYear: null,
        careerPath: null,
        program: null,
        fatherLastName: null
    },
    caretaker: {
        studentId: 1,
        name: null,
        lastName: null,
        fatherLastName: null,
        motherLastName: null,
        rut: null,
        email: null,
        phone: null,
        commune: null,
        address: null,
        address2: null,
        address3: null,
    },
    financingParty: {
        name: null,
        lastName: null,
        fatherLastName: null,
        motherLastName: null,
        rut: null,
        email: null,
        phone: null,
        regionId: null,
        communeId: null,
        address: null,
        address2: null,
        address3: null,
    },
    selectedProgram: null,
    order: {},
    selectedSubjects: [],
    selectedCourses: [],
    selectedLocation: {},
    fullOrder: {},
    studentsWithPrices: [],
    backpackVisible: false,
    lead: null,
    couponApplied: false
}

export default new Vuex.Store({
    state: {
        ...defaultData,
        quotasTimeRemaining : TIME_EXPIRED_PAGE_TIMER,
        localPageTimer : TIME_LOCAL_PAGE_TIMER,
        timerPageExpired : false,
        coursesNotAvailables : [],
        programData: []
    },
    mutations: {
        addProgramData(state,payload) {
            state.programData = payload
            localStorage.setItem('programData', JSON.stringify(state.programData));
        },
        removeSubject(state, subjectIndex){
            state.selectedSubjects.splice(subjectIndex, 1);

            localStorage.setItem('selectedSubjects', JSON.stringify(state.selectedSubjects));
        },

        setOrderInfo(state, order) {
            state.order = order;

            localStorage.setItem('order', JSON.stringify(state.order))
        },

        cleanLocalStorage(state){
            //limpia todo el state y localstorage perteneciente al flujo
            Object.keys(defaultData).forEach(key=>{
                state[key] = defaultData[key];
                localStorage.removeItem(key)
            });
            Cookies.remove('sessionId')
        },
        removeStudent(state, studentId){
            let studentIndex = state.students.findIndex(student => student.id === studentId );
            state.students.splice(studentIndex, 1);

            localStorage.setItem('students', JSON.stringify(state.students))
        },

        setInitialStudentInfo(state){
            if(localStorage.getItem('student')){
                state.student = JSON.parse(localStorage.student);
            }
        },
        addStudentInfo(state, student){
            state.student = {
                ...state.student,
                ...student
            };
            localStorage.setItem('student', JSON.stringify(state.student))
        },

        setInitialStudents(state){
            if(localStorage.getItem('students')){
                state.students = JSON.parse(localStorage.students);
            }
        },
        addStudent(state, student){
            let newStudent = {};
            if(state.students.length > 0){
                let lastStudent = state.students.pop();
                newStudent = {
                    ...lastStudent,
                    ...student
                };
            } else{
                newStudent = {
                    ...student
                }
            }
            state.students.push(newStudent);
            localStorage.setItem('students', JSON.stringify(state.students))
        },
        addNewStudent(state, student){
            state.students.push(student);
        },
        
        addInfoToStudent(state, info){
            let currentStudent = state.students.find(student => student.id === info.student.id);
            currentStudent = {
                ...currentStudent,
                ...info.append
            };
            let currentStudentIndex = state.students.findIndex(student => student.id === currentStudent.id );
            state.students[currentStudentIndex] = currentStudent;

            localStorage.setItem('students', JSON.stringify(state.students))
        },

        setStudents(state, students){
            state.students = students

            localStorage.setItem('students', JSON.stringify(state.students))
        },

        setInitialCaretakerInfo(state){
            if(localStorage.getItem('caretaker')){
                state.caretaker = JSON.parse(localStorage.caretaker);
            }
        },
        addCaretakerInfo(state, caretaker){
            state.caretaker = {
                ...state.caretaker,
                ...caretaker
            };
            localStorage.setItem('caretaker', JSON.stringify(state.caretaker))
        },

        setInitialFinancingPartyInfo(state){
            if(localStorage.getItem('financingParty')){
                state.financingParty = JSON.parse(localStorage.financingParty);
            }
        },
        addFinancingPartyInfo(state, financingParty){
            state.financingParty = {
                ...state.financingParty,
                ...financingParty
            };
            localStorage.setItem('financingParty', JSON.stringify(state.financingParty))
        },

        setInitialSelectedProgramInfo(state){
            if(localStorage.getItem('selectedProgram')){
                state.selectedProgram = JSON.parse(localStorage.selectedProgram);
            }
        },
        addSelectedProgramInfo(state, program){
            state.selectedProgram = program;
            localStorage.setItem('selectedProgram', JSON.stringify(program));
        },

        setInitialSubject(state){
            if(localStorage.getItem('selectedSubjects')){
                state.selectedSubjects = JSON.parse(localStorage.selectedSubjects);
            }
        },
        addSubject(state, subjectAndCourses){
            state.selectedSubjects.push(subjectAndCourses);
            localStorage.setItem('selectedSubjects', JSON.stringify(state.selectedSubjects));
        },
        clearSelectedSubjects(state){
            state.selectedSubjects = [];
        },


        setInitialSelectedCourses(state){
            if(localStorage.getItem('selectedCourses')){
                state.selectedCourses = JSON.parse(localStorage.selectedCourses);
            }
        },
        saveCourseSelected(state, course){
            state.selectedCourses.push(course);
            localStorage.setItem('selectedCourses', JSON.stringify(state.selectedCourses));
        },
        clearSelectedCourses(state){
            state.selectedCourses = [];
            state.students[state.students.length - 1].selectedCourses = [];
            localStorage.setItem('selectedCourses', JSON.stringify(state.selectedCourses));
        },

        setInitialSelectedLocation(state){
            if(localStorage.getItem('selectedLocation')){
                state.selectedLocation = JSON.parse(localStorage.selectedLocation);
            }
        },
        saveSelectedLocation(state, location){
            state.selectedLocation = location;
            localStorage.setItem('selectedLocation', JSON.stringify(state.selectedLocation));
        },

        addInfoToFullOrder(state, info){
            state.fullOrder = {
                ...state.fullOrder,
                ...info
            };
            localStorage.setItem('fullOrder', JSON.stringify(state.fullOrder));
        },
        setInitialFullOrderData(state){
            if(localStorage.getItem('fullOrder')){
                state.fullOrder = JSON.parse(localStorage.fullOrder);
            }
        },
        setInitialOrder(state){
            if(localStorage.getItem('order')){
                state.order = JSON.parse(localStorage.order);
            }
        },

        showBackpack(state){
            state.backpackVisible = true;
            localStorage.setItem('backpackVisible', JSON.stringify(state.backpackVisible));
        },

        hideBackpack(state){
            state.backpackVisible = false;
            localStorage.setItem('backpackVisible', JSON.stringify(state.backpackVisible));
        },

        setInitialBackpackVisible(state){
            if(localStorage.getItem('backpackVisible')){
                state.backpackVisible = JSON.parse(localStorage.backpackVisible);
            }
        },

        setStudentsWithPrices(state){
            state.studentsWithPrices = state.students.filter( student => student.prices);

            localStorage.setItem('studentsWithPrices', JSON.stringify(state.studentsWithPrices));
        },

        setInitialStudentsWithPrices(state){
            if(localStorage.getItem('studentsWithPrices')){
                state.studentsWithPrices = JSON.parse(localStorage.studentsWithPrices);
            }
        },

        setQuotasTimeRemaining(state,timeRemaining){
           state.quotasTimeRemaining = timeRemaining
        },

        setLocalPageTimer(state,timeRemaining){
            state.localPageTimer = timeRemaining
        },
        setTimerPageExpired(state,timerPageExpired){
            state.timerPageExpired = timerPageExpired
        },
        setInitialCoursesNotAvailables(state){
            if(localStorage.getItem('coursesNotAvailables')){
                state.coursesNotAvailables = JSON.parse(localStorage.coursesNotAvailables);
            }
        },
        setInitialProgramDataForRecommend(state) {
            if(localStorage.getItem('programData')){
                state.programData = JSON.parse(localStorage.programData);
            }
        },
        setCoursesNotAvailables(state,coursesNotAvailables){
            state.coursesNotAvailables = coursesNotAvailables;
            localStorage.setItem('coursesNotAvailables',JSON.stringify(coursesNotAvailables));
        },
        clearCoursesNotAvailables(state){
            state.coursesNotAvailables = [];
            localStorage.removeItem('coursesNotAvailables');
        },
        setLeadInfo(state, lead) {
            state.lead = lead;
            sessionStorage.setItem('lead', JSON.stringify(lead))
        },
        setInitialLeadInfo(state){
            if(sessionStorage.getItem('lead')){
                state.lead = JSON.parse(sessionStorage.lead);
            }
        },
        setCouponApplied(state, value){
            state.couponApplied = value
            localStorage.setItem('couponApplied', value);
        }
    },
    actions: {
        setProgramData(context,payload) {
            return new Promise((resolve) => {
                context.commit('addProgramData',payload)
                resolve()
            })
        },
        removeSubject(context, subjectIndex){
            return new Promise((resolve)=>{

                context.commit('removeSubject', subjectIndex);
                resolve()
            })
        },

        setOrderInfo(context, order){
            context.commit('setOrderInfo', order)
        },

        cleanLocalStorage(context){
            return new Promise((resolve) => {
                context.commit('cleanLocalStorage');
                resolve();
            });
        },
        removeStudent(context, studentId){
            return new Promise((resolve) => {
                context.commit('removeStudent', studentId);
                resolve();
            });
        },
        setInitialStudentInfo(context){
            context.commit('setInitialStudentInfo');
        },
        addStudentInfo(context, student){
            return new Promise((resolve) => {
                context.commit('addStudentInfo', student);
                resolve();
            });
        },
        setInitialStudents(context){
            context.commit('setInitialStudents');
        },
        addStudent(context, student){
            return new Promise((resolve) => {
                context.commit('addStudent', student);
                resolve();
            });
        },
        addNewStudent(context, student){
            return new Promise((resolve) => {
                context.commit('addNewStudent', student);
                resolve();
            });
        },
        addInfoToStudent(context, info){
            return new Promise((resolve) => {
                context.commit('addInfoToStudent', info);
                resolve();
            });
        },

        setStudents(context, students){
            return new Promise( (resolve) => {
                context.commit('setStudents', students);
                resolve();
            });
        },

        setInitialCaretakerInfo(context){
            context.commit('setInitialCaretakerInfo');
        },
        addCaretakerInfo(context, caretaker){
            return new Promise((resolve) => {
                context.commit('addCaretakerInfo', caretaker);
                resolve();
            });
        },

        setInitialFinancingPartyInfo(context){
            context.commit('setInitialFinancingPartyInfo');
        },
        addFinancingPartyInfo(context, financingParty){
            return new Promise((resolve) => {
                context.commit('addFinancingPartyInfo', financingParty);
                resolve();
            });
        },

        setInitialSelectedProgramInfo(context){
            context.commit('setInitialSelectedProgramInfo');
        },
        addSelectedProgramInfo(context, program){
            return new Promise((resolve) => {
                context.commit('addSelectedProgramInfo', program);
                context.commit('addStudent', {program});
                resolve();
            });
        },

        setInitialSubject(context){
            context.commit('setInitialSubject');
        },
        addSubject(context, subjectAndCourses){
            return new Promise((resolve) => {
                context.commit('addSubject', subjectAndCourses);
                context.commit('addStudent', { selectedSubjects: [...context.state.selectedSubjects] });
                resolve();
            });
        },
        clearSelectedSubjects(context){
            return new Promise((resolve) => {
                context.commit('clearSelectedSubjects');
                resolve();
            });
        },


        setInitialSelectedCourses(context){
            context.commit('setInitialSelectedCourses');
        },
        saveCourseSelected(context, course){
            return new Promise((resolve) => {
                context.commit('saveCourseSelected', course);
                context.commit('addStudent', {selectedCourses:  [...context.state.selectedCourses] });
                resolve();
            });
        },
        clearSelectedCourses(context){
            return new Promise((resolve) => {
                context.commit('clearSelectedCourses');
                resolve();
            });
        },

        setInitialSelectedLocation(context){
            context.commit('setInitialSelectedLocation');
        },
        saveSelectedLocation(context, location){
            return new Promise((resolve) => {
                context.commit('saveSelectedLocation', location);
                context.commit('addStudent', {selectedLocation:  { ...context.state.selectedLocation } });
                resolve();
            });
        },

        addInfoToFullOrder(context, info){
            return new Promise(resolve => {
                context.commit('addInfoToFullOrder', info);
                resolve();
            });
        },
        setInitialFullOrderData(context){
            context.commit('setInitialFullOrderData');
        },

        setInitialOrder(context){
            context.commit('setInitialOrder');
        },

        showBackpack(context){
            return new Promise(resolve => {
                context.commit('showBackpack');
                resolve();
            })
        },

        hideBackpack(context){
            return new Promise(resolve => {
                context.commit('hideBackpack');
                resolve();
            })
        },

        setInitialBackpackVisible(context){
            context.commit('setInitialBackpackVisible');
        },

        setStudentsWithPrices(context){
            context.commit('setStudentsWithPrices');
        },

        setInitialStudentsWithPrices(context){
            context.commit('setInitialStudentsWithPrices');
        },

        setLocalPageTimer(context,timeRemaining){
            const {timerPageExpired} = context.state;
            // console.log(timeRemaining)
            return new Promise(resolve=>{
                if(timeRemaining === '00:00' && !timerPageExpired){
                    context.commit('cleanLocalStorage');
                    context.commit('setLocalPageTimer',TIME_LOCAL_PAGE_TIMER);    
                    router.replace("/")
                    window.$('#helperSessionModal')[0].click();
                    setTimeout(()=>{ window.$('#helperSessionClosedModal')[0].click(); },1000)
                }else{
                    if (timerPageExpired){
                        context.commit('setLocalPageTimer',TIME_LOCAL_PAGE_TIMER);
                        context.commit('setTimerPageExpired',false); 
                    }else{
                        context.commit('setLocalPageTimer',timeRemaining);      
                    }
                }
                resolve();
            });
        },
        setQuotasTimeRemaining(context,timeRemaining){
            const isBetween = moment(timeRemaining,'mm:ss').isBetween(
                moment('00:00','mm:ss'),
                moment(context.state.quotasTimeRemaining,'mm:ss')
            );
            if(isBetween){
                context.commit('setQuotasTimeRemaining',timeRemaining);
            }else{
                context.commit('cleanLocalStorage');
                context.commit('setQuotasTimeRemaining',TIME_EXPIRED_PAGE_TIMER);
                router.replace("/")
                setTimeout(()=>{ window.$('#helperSessionExpiredModal')[0].click(); },1000)
            }
        },
        setInitialCoursesNotAvailables(context){
            context.commit('setInitialCoursesNotAvailables');
        },
        setInitialProgramDataForRecommend(context){
            context.commit('setInitialProgramDataForRecommend');
        },
        setCoursesNotAvailables(context,coursesNotAvailables){
           
            context.commit('setCoursesNotAvailables',coursesNotAvailables);
            window.$('#helperCoursesNotAvailable')[0].click();
              
        },
        clearCoursesNotAvailables(context){
            return new Promise(resolve => {
                context.commit('clearCoursesNotAvailables');
                resolve();
            })
              
        },
        setLeadInfo(context, lead){
            context.commit('setLeadInfo', lead)
        },
        setInitialLeadInfo(context){
            context.commit('setInitialLeadInfo');
        },
        setCouponApplied(context, value){
            context.commit('setCouponApplied', value)
        },
    },
    getters: {
        lastStudent(state){
            if (state.students.length){
                let student = state.students.find((student, index, array) => {
                    return array.length === (index + 1);
                });
                return student;
            } else{
                return {
                    caretakerId: null,
                    financingPartyId: null,
                    name: null,
                    lastName: null,
                    rut: null,
                    email: null,
                    phone: null,
                    commune: null,
                    address: null,
                    address2: null,
                    address3: null,
                    establishment: null,
                    graduationYear: null,
                    averageScore: null,
                    careerPath: null,
                    program: null,
                    fatherLastName: null,
                    gender: null,
                }
            }
        },
        getLead: state => {
            return state.lead
        },
        getCouponApplied: state => {
            return state.couponApplied
        },
    }
});