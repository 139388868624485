<template>
  <layout-default>
    <div class="wse-page">
      <Banner :modality="modality" />
      <Detail :modality="modality" />
      <!--TestSection /-->
      <Qualify />
      <Programs />
      <ModalitiesPlan :show="false" />
      <ModalClient v-if="next" @continuar="nextModal" @closeModal="next=false" />
    </div>

  </layout-default>
</template>

<script>
import axios from 'axios'
import LayoutDefault from "@/layouts/Default";
import Banner from "@/components/modalities/Banner";
import Detail from "@/components/modalities/Detail";
// import TestSection from "@/components/modalities/TestSection";
import Qualify from "@/components/modalities/Qualify";
import Programs from "@/components/modalities/Programs";
import ModalitiesPlan from "@/components/modalities/ModalitiesPlan";
import ModalClient from "@/components/client/ModalClient";

export default {
  components: {
    LayoutDefault,
    Banner,
    Detail,
    // TestSection,
    Qualify,
    Programs,
    ModalitiesPlan,
    ModalClient
  }, 
  data(){
    return {
      modality: null,
      next: false,
      slugGo: '',
      title: ''
    }
  },
  metaInfo() {
    return {
      title: 'Wall Street English | ' + this.title,
    };
  },
  mounted(){
    this.title = this.getModalityName()
    this.getData()
    if(sessionStorage.getItem('lead')){
      this.next = false
    } else {
      this.next = true
    } 
  },
  methods: {
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}modalityDetailData.json`)
        const modalities = data;
        this.modality = modalities.find(item => item.slug == this.$route.params.slug)
      } catch (error) {
        console.log(error)
      }
    },
    nextModal(){
      this.next = false
    },
    getModalityName(){
      const slug = this.$route.params.slug;
      switch (slug) {
        case 'global-online-center':
          return 'GOC';
        case '100-online':
          return 'ONLINE';
        case '100-in-center':
          return 'IN CENTER';
        case 'teens-online':
          return 'TEENS';
        default:
          return 'FULL ACCESS';
      }
    },
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  },
}
</script>

<style lang="scss" scoped>

</style>