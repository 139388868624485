<template>
  <div class="card-program" v-if="program">
    <div class="card-program-body">
      <div class="ribbon ribbon-top-right" v-if="program.Metas.extra_month">
        <div>+ {{ program.Metas.quantity_month }} {{ program.Metas.quantity_month > 1 ? ' meses ' : ' mes '  }} ¡Gratis!</div>
        <span class="border-cut"></span>
      </div>
      <div class="header">
        <div class="header-description">
          <span class="month">{{ getNameShow(program.name, 0).toUpperCase() }} Meses</span>
          <span class="modality">{{getModalityName()}}</span>
        </div>
      </div>
      <div class="content">
        <div class="content-featured" v-for="(detail, k) in program.Details" :key="k" >
          <div class="check">
            <img src="@/assets/img/check_black.png" alt="check" />
          </div>
          <p>
            {{ detail.ProgramDetail.value }} {{ detail.name }}
          </p>
        </div>
      </div>
      <div class="prices">
        <div class="price-list" v-if="program.pricelist != program.price">
          <span class="price-list-text">Precio Lista</span>
          <span class="price-list-value">{{program.pricelist | moneyFormatter}}</span>
        </div>
        <div class="price-list" v-else>
          <span class="price-list-text">&nbsp;</span>
          <span class="price-list-value">&nbsp;</span>
        </div>

        <div class="price-promo" v-if="program.pricelist != program.price">
          <span class="price-promo-text">Precio Promocional</span>
          <span class="price-promo-value">{{program.price | moneyFormatter}}*</span>
        </div>
        <div class="price-promo" v-else>
          <span class="price-promo-text">Precio Lista</span>
          <span class="price-promo-value">{{program.pricelist | moneyFormatter}}*</span>
        </div>
        <span class="subtitle">*Matrícula incluye descuento de 50%  <del>$120.000</del> $60.000</span>
        <span class="subtitle">*Promoción válida hasta Marzo año 2023</span>
        <span class="subtitle">*Paga hasta en 12 cuotas sin interés</span>
      </div>
      <div class="flex justifi-content-start w-100">
        <span v-if="getModalityName() == 'IN CENTER'" class="text-error mb-2">
          * Plan deshabilitado por emergencia sanitaria 
        </span>
      </div>
       <button class="btn btn-slide" v-if="!selectedPlan" @click="selectPlan">
        Seleccionar Plan
      </button> 
      </div>

    <div class="locations-box" v-if="selectedPlan">
      <div class="wrapper-box">
        <CustomSelect :locations="locationFormat" @selected="choseLocation" />
      </div>
      <button class="btn" @click="chosePlan" >
        Comprar este plan
      </button>
    </div>
    <ModalSelectedPlan
      v-if="confirmPlan"
      :title="locationSelected ? 'Estas por comprar' :'Selecciona una Sede'"
      @closeModal="confirmPlan=false"
    />
  </div>
</template>

<script>
import ModalSelectedPlan from "@/components/client/ModalSelectedPlan";
import CustomSelect from "@/components/commons/CustomSelect";
export default {
  components:{
    ModalSelectedPlan,
    CustomSelect
  },
  props: {
    program:{
      type: Object,
      default: null
    }
  },
  data(){
    return {
      location: 'Seleciona tu sede',
      openSelect: false,
      selectedPlan: false,
      locationSelected: null,
      confirmPlan: false,
      locationFormat: []
    }
  },
  mounted(){
    // const program = this.$store.state.selectedProgram;
    const program = this.program
    //const locationList = program.Locations;
    const locationList = program.Locations.sort((l1, l2) => (l1.regionId < l2.regionId) ? -1 : (l1.regionId > l2.regionId) ? 1 : 0);
    const regions = locationList.map(row => row.Region.name)
    const regionsOnly = [...new Set(regions)]
    let arrayFinal = []
    regionsOnly.forEach(region => {
      const childrens =  locationList.filter(loc => loc.Region.name === region);
      arrayFinal.push({ region: region, locations: childrens})
    })
    this.locationFormat = arrayFinal;
  },
  methods:{
    openCloseSelect(){
      this.openSelect = !this.openSelect
    },
    async selectPlan(){
      if(this.getModalityName() === 'IN CENTER'){ // si la modalidad es in center ho hace nada por el tema de pandemia
        return
      } else {
        await this.$store.dispatch('addSelectedProgramInfo', this.program)
        this.confirmPlan = true
      }
    },
    getLocation(location){
      this.locationSelected = location
      this.location =  location.name
      this.openSelect = false
    },
    async chosePlan(){
      this.confirmPlan = true;
      if(this.locationSelected){
        await this.$store.dispatch("saveSelectedLocation", this.locationSelected)
      }
    },
    choseLocation(event){
      this.locationSelected = event
    },
    getModalityName(){
      const slug = this.$route.params.slug;
      switch (slug) {
        case 'global-online-center':
          return 'GOC';
        case '100-online':
          return 'ONLINE';
        case '100-in-center':
          return 'IN CENTER';
        case 'teens-online':
          return 'TEENS';
        default:
          return 'FULL ACCESS';
      }
    },
    getNameShow(name, position){
      const str = name.toLowerCase()
      let array = []
      if(str.includes("meses")){
        array = str.split("meses")
        return array[position]
      } else {
        array = str.split(" ")
        if(position === 0){
          return `${array[0]} ${array[1]}`
        } else {
          return array[array.length -1]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-program{
    background: #F5F5F5;
  }
  .card-program-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 10px 25px;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    justify-content: space-between;

    .header {
      width: 100%;
      margin-bottom: 20px;
      .header-description{
        padding: 0 9px;
        padding-top: 5px;
        width: 156px; /* 106 */
        height: 115px;
        background: #0082A9;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .month{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 20px;
          line-height: 100%;
          color: #FFFFFF;
        }
        .modality{
          font-family: 'Open Sans', sans-serif !important;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #FFFFFF;
        }
      }
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      .content-featured{
        display: flex;
        .check{
          width: 24px;
          margin-right: 10px;
          img{
            width: 100%;
          }
        }
        p{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 140%;
          color: #404040;
        }
      }
    }

    .prices {
      width: 100%;
      display: flex;
      flex-direction: column;
      .price-list{
        display: flex;
        justify-content: space-between;
        .price-list-text,.price-list-value{
          font-family:'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          display: flex;
          align-items: center;
          color: #0082A9;
        }
        .price-list-value{
          text-decoration-line: line-through;
        }
      }
      .price-promo{
        display: flex;
        justify-content: space-between;
        .price-promo-text,.price-promo-value{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 18px;
          line-height: 32px;
          color: #003359;
          @media (max-width: 321px) {
            font-size: 15px;
          }
        }
      }
    }

    .subtitle{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #003359;
      margin-bottom: 10px;
    }
    .btn-slide{
      width: 100%;
      background: #0082A9;
      background: linear-gradient(to left, transparent 50%, #0082A9 50%) right;
      background-size: 200%;
      border: 1px solid #0082A9;
      color: #0082A9;
      border-radius: 4px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Avenir LT Pro Black';
      font-style: normal;
      font-weight: 750;
      font-size: 20px;
      line-height: 140%;
      cursor: pointer;
      -webkit-transition: ease-out 0.4s;
      -moz-transition: ease-out 0.4s;
      transition: ease-out 0.4s;
      box-shadow: inset 0 0 0 0 #0082A9;
      &:hover{
        color: #fff !important;
        background-position: left;
      }
    }
  }

  /* common */
.ribbon {
  width: 88px;
  height: 81px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before{
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 4px solid #F12C3E;
}
.ribbon div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 83px;
  height: 71px;
  background-color: #F12C3E;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font-family: 'Avenir LT Pro Light';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  padding-bottom: 7px;
}
.ribbon-top-right {
  top: -9px;
  right: 15px;
}
.ribbon-top-right::before{
  border-top-color: transparent;
  border-right-color: transparent;
  transform: rotate(-90deg);
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
  /*transform: rotate(-45deg)*/
}
.ribbon-top-right div {
  left: 4px;
  top: 0px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.border-cut{
  height: 10px;
  border-right: 41.5px solid transparent;
  border-bottom: 10px solid #F5F5F5;
  border-left: 41.5px solid transparent;
  z-index: 90;
  position: absolute;
  right: 1px;
  left: 4px;
  bottom: 0;
  background: #F12C3E;
}

.locations-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0px 0px 8px 8px;

  button{
    background: #003359;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 29px;
    border-radius: 4px;
    font-family:'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #FFFFFF;
  }

}

.wrapper-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.select_box{
  width: 100%;
  height: 50px;
  overflow: hidden;
  border: 1px solid #0082A9;
  position: relative;
  padding-left: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  span{
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    color: #406683;
  }
}
.select_box:after{
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f00;
  position: absolute;
  top: 40%;
  right: 5px;
  content: "";
  z-index: 98;
 }
.select_box select{
  width: 100%;
  border: 0;
  position: relative;
  z-index: 99;
  background: none;
}

.select_box_options{
  width: 100%;
  border: 1px solid #0082A9;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 3px;

  display: block;
  position: absolute;
  background-color: #fff;
  /*box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);*/
  z-index: 2;
  top: 50px;
  right: 0x;
  left: 0px;

  span{
    cursor: pointer;
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #404040;
    padding: 8px 0;
  }
}

select {
  width: 100%;
  border: 1px solid #0082A9;
  color: #406683;
  border-radius: 4px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Avenir LT Pro Light';
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  background-color: transparent !important;
}

</style>