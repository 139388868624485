<template>
  <div class="programs" id="programs">
    <div class="container">
      <div class="programs-title">
        <h3>ALCANZA TUS OBJETIVOS</h3>
        <h4>Selecciona el mejor plan para ti</h4>
      </div>
      <div class="programs-list">
        <TeensModality
          v-if="datos && isteenmodality"
          :programsFull="programsFull"
          :programsBasic="programsBasic"
        />
        <div
          class="carousel-programs"
          v-if="media.isDesktop && !isteenmodality"
        >
          <carousel
            :perPage="3"
            paginationColor="#A6D3E1"
            paginationActiveColor="#A6D3E1"
            :navigationEnabled="true"
            :paginationPadding="2"
            :navigation-next-label="navigationNext"
            :navigation-prev-label="navigationPrev"
            :paginationSize="8"
          >
            <slide v-for="(program, index) in programs" :key="index">
              <div class="item">
                <ItemProgram :program="program" />
              </div>
            </slide>
          </carousel>
        </div>
        <div class="content-mobile" v-if="!media.isDesktop">
          <div class="item" v-for="(program, index) in programs" :key="index">
            <ItemProgram :program="program" />
          </div>
        </div>
        <div class="sedes-title" v-if="isfullaccess">
          <h4>Nuestros centros</h4>
        </div>
        <locations class="accordion-sedes" v-if="datos && isfullaccess">
          <location-item v-for="data of datos" :key="data.title">
            <template slot="accordion-trigger">
              <h4 class="accordion-title accordion-item-title closed">
                {{ data.title }}
              </h4>
              <img
                src="@/assets/img/keyboard_arrow_down.svg"
                alt="keyboard_arrow_down"
                class="arrow when-closed"
                width="24"
                height="24"
              />
            </template>
            <template slot="accordion-content">
              <p class="sedes-subtitle"></p>
              <div v-if="data.title !== 'Centros Santiago'">
                <p>{{ data.subtitle }}</p>
                <p class="sedes-subtitle"></p>
                <p>{{ data.description }}</p>
                <p class="sedes-subtitle"></p>
                <iframe :src="data.location" width="750" height="290"> </iframe>
              </div>
              <div v-if="data.title === 'Centros Santiago'">
                <locations class="accordion-sedes" v-if="datos && isfullaccess">
                  <location-item
                    v-for="data of data.subtitles"
                    :key="data.title"
                  >
                    <template slot="accordion-trigger">
                      <h5 class="accordion-title accordion-item-title closed">
                        {{ data.name }}
                      </h5>
                      <img
                        src="@/assets/img/keyboard_arrow_down.svg"
                        alt="keyboard_arrow_down"
                        class="arrow when-closed"
                        width="24"
                        height="24"
                      />
                    </template>
                    <template slot="accordion-content">
                      <p>{{ data.adress }}</p>
                      <iframe :src="data.map" width="750" height="290">
                      </iframe>
                      <p>{{ data.hours }}</p>
                    </template>
                  </location-item>
                </locations>
                <p>{{ data.description }}</p>
              </div>
            </template>
          </location-item>
        </locations>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { Carousel, Slide } from 'vue-carousel'
  import ItemProgram from "@/components/modalities/ItemProgram";
  import ChevronRight from '@/assets/img/right-arrow.svg';
  import ChevronLeft from '@/assets/img/left-arrow.svg';
  import Locations from './Locations.vue';
  import LocationItem from './Location-item.vue';
  import TeensModality from './TeensModality.vue'


export default {
  components:{
    Carousel, Slide, ItemProgram,  Locations, LocationItem, TeensModality,
  },
  data(){
    return{
      programs: [],
      datos: null,
      programsFull:[],
      programsBasic:[],
    }
  },

  mounted(){
    
    this.getPrograms()
    let aux = [
      {
        title: "Centro Viña del Mar",
        subtitle:"Avenida Libertad 149, Viña del Mar.",
        location: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2424.9953124151916!2d-72.61360940920285!3d-38.73384019777792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sEspa%C3%B1a%20460%2C%20Piso%208%20oficina%20809.%20Edificio%20Centro%20Plaza%2C%20Temuco!5e0!3m2!1ses!2sar!4v1675110891803!5m2!1ses!2sar",
        description: "Horario de atención: Lunes a viernes de 10:00 a 20:00 hrs. y sábado de 10:00 a 14:00 hrs. ",
      },
      {
        title: "Centros Santiago",
        subtitles:[
        {name:"Centro Providencia", adress:"Holanda 100, Of 501-506, Piso 5, Providencia (Antiguo centro Costanera).",map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.1226734628403!2d-70.60568604895323!3d-33.42004598068827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cfb1e435f62f%3A0xd833192119c0aae2!2sHolanda%20100%2C%207510021%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sar!4v1676489647473!5m2!1ses!2sar", hours:"Horario de atención: Lunes a viernes de 09:00 a 21:30 hrs. y sábado de 10:00 a 14:00 hrs."},
        {name:"Centro La Florida", adress:"Av Vicuña Mackenna poniente 7431, segundo piso, local 9.", map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.1914500221296!2d-70.60182504895091!3d-33.52240768065888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662d06040550129%3A0x829356e0c1a93c60!2sAv.%20Vicu%C3%B1a%20Mackenna%20Pte.%207431%2C%20La%20Florida%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sar!4v1676489717016!5m2!1ses!2sar", hours:"Horario de atención: Lunes a viernes de 10:00 a 20:00 hrs. y sábado de 10:00 a 14:00 hrs."},
        {name:"Centro Maipú", adress:"Monumento 2194, Maipú.",map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.7720520309167!2d-70.76136764895126!3d-33.50730728066336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662dcd4a5017b41%3A0x266056462a9a89f8!2sMonumento%202194%2C%209251545%20Maip%C3%BA%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sar!4v1676489821732!5m2!1ses!2sar", hours:"Horario de atención: Lunes a viernes de 09:00 a 21:30 hrs. y sábado de 10:00 a 14:00 hrs.(2 SÁBADOS PRESENCIAL, 2 SÁBADOS ONLINE)"},
        {name:"Centro Nueva Las Condes", adress:"Cerro el Plomo 5420, local 102, Las Condes.", map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.6707270090233!2d-70.57714684895366!3d-33.405753680692484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf9c0bcbfbc1%3A0xe637f689fdf56386!2sCerro%20El%20Plomo%205420%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sar!4v1676489915726!5m2!1ses!2sar", hours:"Horario de atención: Lunes a viernes de 09:00 a 20:00 hrs. y sábado de 10:00 a 14:00 hrs."}],
      },
      {
        title: "Centro Rancagua",
        subtitle: "Estado 539, Rancagua.",
        location: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3300.9951080770556!2d-70.7427476778896!3d-34.172042741625006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sEstado%20540%2C%20Rancagua!5e0!3m2!1ses!2sar!4v1676490515685!5m2!1ses!2sar",
        description: "Horario de atención: Lunes a viernes de 09:00 a 21:30 hrs. y sábado de 10:00 a 14:00 hrs. ",
      },
      {
        title: "Centro Concepción",
        subtitle: "Lincoyan 282, Edificio Consorcio, oficina 501, piso 5, Concepción.",
        location: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3193.5201690724916!2d-73.05439194882747!3d-36.83001647984402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%20%20%20%20%20%20%20%20subtitle%3A%20%22Lincoyan%20282%2C%20Edificio%20Consorcio%2C%20oficina%20501%2C%20piso%205%2C%20Concepci%C3%B3n.%22%2C!5e0!3m2!1ses!2sar!4v1675202054030!5m2!1ses!2sar",
        description: "Horario de atención: Lunes a viernes de 00:00 a 20:00 hrs. y sábado de 10:00 a 14:00 hrs. ",
      },
      {
        title: "Centro Temuco",
        subtitle: "España 460, Piso 8 oficina 809. Edificio Centro Plaza, Temuco",
        location: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3112.2970579407597!2d-72.61447304878037!3d-38.73394497949591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%20Espa%C3%B1a%20460%2C%20Piso%208%20oficina%20809.%20Edificio%20Centro%20Plaza%2C%20Temuco!5e0!3m2!1ses!2sar!4v1675202112154!5m2!1ses!2sar",
        description: "Horario de atención: Lunes a viernes de 09:00 a 21:00 hrs. y sábado de 10:00 a 14:00 hrs. ",
      },

    ];
    this.datos=aux
  },
  methods:{




    async getPrograms(){
      if (this.$route.params.slug === 'teens-online'){
        const parametersFull = {year: 'teens-100-online-full', defaults: true }
        const parametersBasic = {year: 'teens-100-online-basico', defaults: true }
        try {
          let all = []
          let allbasic =[]
          const { data: programFull } = await axios.get(`${process.env.VUE_APP_API_URL}/programs`, { params: parametersFull})
          const { data: programBasic } = await axios.get(`${process.env.VUE_APP_API_URL}/programs`, { params: parametersBasic})
          programFull.forEach(element => {
            const numberString = element.name.match(/\d/g).join("")
            all.push({...element, month: parseInt(numberString)})
          });
          let filterFull;
          if(process.env.VUE_APP_MIN_PRICE && process.env.VUE_APP_MIN_PRICE_LIST){
            filterFull = all.filter(prog => prog.price > process.env.VUE_APP_MIN_PRICE && prog.pricelist > process.env.VUE_APP_MIN_PRICE_LIST)
          } else {
            filterFull = all.filter(prog => prog.price > 800000 && prog.pricelist > 1000000 );
          }
          this.programsFull = filterFull.sort((x,y) => {return x.month - y.month});
          //Ordena programas teens basic.
          programBasic.forEach(element => {
            const numberString = element.name.match(/\d/g).join("")
            allbasic.push({...element, month: parseInt(numberString)})
          });

          let filterBasic;
          if(process.env.VUE_APP_MIN_PRICE && process.env.VUE_APP_MIN_PRICE_LIST){
            filterBasic = allbasic.filter(prog => prog.price > process.env.VUE_APP_MIN_PRICE && prog.pricelist > process.env.VUE_APP_MIN_PRICE_LIST)
          } else {
            filterBasic = allbasic.filter(prog => prog.price > 800000 && prog.pricelist > 1000000 );
          }
          this.programsBasic = filterBasic.sort((x,y) => {return x.month - y.month});
        } catch (error) {
          console.log(error)
        }

      }
      else {
        const parameters = {year: this.$route.params.slug, defaults: true }
        try {
          let all = []
          const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/programs`, { params: parameters})
          data.forEach(element => {
            const numberString = element.name.match(/\d/g).join("")
            all.push({...element, month: parseInt(numberString)})
          });
          let filter;
          if(process.env.VUE_APP_MIN_PRICE && process.env.VUE_APP_MIN_PRICE_LIST){
            filter = all.filter(prog => prog.price > process.env.VUE_APP_MIN_PRICE && prog.pricelist > process.env.VUE_APP_MIN_PRICE_LIST)
          } else {
            filter = all.filter(prog => prog.price > 800000 && prog.pricelist > 1000000 );
          }
          this.programs = filter.sort((x,y) => {return x.month - y.month});
        } catch (error) {
          console.log(error)
        }
        }

    }
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    navigationNext() {
      const chevronRight = ChevronRight;
      return `<img src="${chevronRight}" class="w50 h50 custom-arrow"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      return `<img src="${chevronLeft}" class="w50 h50 custom-arrow" />`;
    },
    isfullaccess(){
     if (this.$route.params.slug === 'full-access') {
       return true
     } else {return false}

    },
    isteenmodality(){
      if (this.$route.params.slug === 'teens-online'){
        return true
      } else {return false}
    },

  }
}
</script>

<style lang="scss" scoped>
.programs {
  margin-bottom: 80px;
  position: relative;
  .programs-title {
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 768px) {
      padding: 50px 40px;
    }
    h3 {
      font-family: "Avenir LT Pro Light";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #003359;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    h4 {
      font-family: "Avenir LT Pro Black";
      font-style: normal;
      font-weight: 750;
      font-size: 35px;
      line-height: 140%;
      text-align: center;
      color: #003359;
      opacity: 0.8;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 120%;
      }
    }
  }
  .item {
    padding: 10px;
  }
}
.content-mobile {
  width: 100%;
  .item {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss">
#programs {
  .programs .VueCarousel-wrapper {
    overflow: unset;
    overflow-x: clip;

    z-index: 2;
  }
  .VueCarousel-navigation-prev {
    padding: 20px !important;
  }
  .VueCarousel-navigation-next {
    padding: 20px !important;
  }
  .VueCarousel-dot {
    background-color: rgb(245, 97, 110) !important;
  }
  .VueCarousel-dot--active {
    background-color: rgb(245, 97, 110) !important;
  }
  .w50 {
    width: 50px;
  }
  .h50 {
    height: 50px;
  }
  .custom-arrow {
    background: #f5f5f5;
  }

  .accordion-sedes {
    color: rgba(1, 51, 89, 255);
    font-family: "Avenir LT Pro Light";
    font-size: 20px;
  }
  .sedes-title {
    color: rgba(1, 51, 89, 255);
    font-weight: bold;
    font-family: "Avenir LT Pro Black";
    text-align: center;
    padding: 1.5rem;
  }
  .sedes-subtitle {
    color: rgba(1, 51, 89, 255);
    font-weight: bold;
    font-family: "Avenir LT Pro Black";
  }

  .accordion-title {
    font-weight: bold;
  }
}
</style>
