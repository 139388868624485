<template>
  <div class="carousel-home">
    <h2 class="carousel-title">Aprende con el método Wall Street English</h2>
    <carousel
      :perPage="1"
      paginationColor="#A6D3E1"
      paginationActiveColor="#A6D3E1"
      :navigationEnabled="true"
      :paginationPadding="2"
      :navigation-next-label="navigationNext" 
      :navigation-prev-label="navigationPrev"
      :paginationSize="8"
    >
      <slide v-for="(slide, index) in sliders" :key="index">
        <div class="item">
          <div class="item-image">
            <img :src="require(`@/assets/img/${slide.image}`)" :alt="slide.subtitle" />
          </div>
          <div class="item-content">
            <h4 class="item-subtitle">{{ slide.subtitle }}</h4>
            <p class="item-description">{{ slide.description }}</p>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>
<script>
  import axios from 'axios'
  import { Carousel, Slide } from 'vue-carousel'
  import ChevronRight from '@/assets/img/right-arrow.svg';
  import ChevronLeft from '@/assets/img/left-arrow.svg';

export default {
  components:{
    Carousel,
    Slide,
  },
  data(){
    return {
      sliders: []
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}homeCarouselData.json`)
        this.sliders = data;
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    navigationNext() {
      const chevronRight = ChevronRight;
      return `<img src="${chevronRight}"/>`;
    },
    navigationPrev() {
      const chevronLeft = ChevronLeft;
      return `<img src="${chevronLeft}"/>`;
    },
  }
}
</script>
<style lang="scss" scoped>
  .carousel-home{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    @media (max-width: 768px){
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }
  .carousel-title{
    width: 100%;
    font-family: 'Avenir LT Pro Black';
    color: #003359;
    font-size: 24px !important;
    line-height: 120% !important;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  .item{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .item-image{
      width: 80px;
      margin-bottom: 20px;
      img{
        width: 100%;
        height: auto;
      }
    }

    .item-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .item-subtitle{
        font-family: 'Open Sans', sans-serif !important;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #404040;
        margin-top: 5px;
        margin-bottom: 10px;
      } 

      .item-description{
        text-align: center;
        color: #404040;
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        margin: 0;
      }

    }
  }
  .VueCarousel-navigation-prev{
    left: 20px !important;
  }
  .VueCarousel-navigation-next{
    right: 20px !important;
  }
</style>

<style>
   .VueCarousel-navigation-prev{
    left: 20px !important;
  }
  .VueCarousel-navigation-next{
    right: 20px !important;
  }
  .VueCarousel-pagination{
    margin-top: 10px !important;
  }
  .VueCarousel-dot--active{
    width: 24px !important;
    border-radius: 8px !important;
  }
</style>