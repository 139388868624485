<template>
<div class="main-footer">
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="block">
            <h4>Donde empezar</h4>
            <a href="https://www.wallstreetenglish.cl/cursos-personas" target="_blank">Aprenda inglés</a>
            <!--a href="/modality/global-online-center">Global Online Center</a-->
            <a href="/modality/100-online">100% Online</a>
            <a href="/modality/100-in-center">In Center</a>
            <a href="/modality/full-access">Full Access</a>
            <!--a href="/test">Conoce tu nivel de Inglés</a-->
            <router-link to="/politica-de-privacidad-y-confidencialidad">
              Políticas de privacidad y confidencialidad 
            </router-link>
            <a href="/terminos-condiciones.pdf" target="_blank" >Términos y Condiciones</a>
            <router-link to="/informaciondelvendedor">
              Información del vendedor
            </router-link>
            <router-link to="/retracto">
              Retracto
            </router-link>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="block">
            <h4>Nuestras Redes</h4>
            <div class="socials">
              <a href="https://www.facebook.com/wsichile" target="_blank" class="icon">
                <img src="@/assets/svg/logo-facebook.svg" alt="webpay_logo" />
              </a>
              <a href="https://twitter.com/wse_chile" target="_blank" class="icon">
                <img src="@/assets/svg/logo-twiter.svg" alt="webpay_logo" />
              </a>
              <a href="https://www.linkedin.com/company/wallstreetenglishchile" target="_blank" class="icon">
                <img src="@/assets/svg/logo-linkeding.svg" alt="webpay_logo" />
              </a>
              <a href="https://www.instagram.com/wsechile/" target="_blank" class="icon">
                <img src="@/assets/svg/logo-instagram.svg" alt="webpay_logo" />
              </a>
              <a href="#" target="_blank" class="icon">
                <img src="@/assets/svg/logo-youtube.svg" alt="webpay_logo" />
              </a>
            </div>
            <h4>Paga en Línea</h4>
            <div class="logo-pay">
              <img src="@/assets/img/webpay_logo.png" alt="webpay_logo" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="block">
            <h4>Contacto</h4>
            <a href="tel:800203636" class="contact-info">
              <div class="icon">
                <img src="@/assets/svg/phone_in_talk.svg" alt="phone_in_talk" />
              </div>
              <span>800 20 36 36</span>
            </a>
            <!--a href="tel:+56228713695" target="_blank" class="contact-info">
              <div class="icon">
                <img src="@/assets/svg/phone_in_talk.svg" alt="phone_in_talk" />
              </div>
              <span>+56 2 2871 3695</span>
            </a-->
            <a href="mailto:info@wsi.cl" class="contact-info">
              <div class="icon">
                <img src="@/assets/svg/email.svg" alt="email" />
              </div>
              <span>info@wsi.cl</span>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="block">
            <div class="content-logo">
              <div class="vertical-logo">
                <img :src="logoFooter" alt="logo-vertical" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-footer">
    <p>© Wall Street English {{ new Date().getFullYear() }}. All rights reserved</p>
  </div>  
  <img src="@/assets/img/logo-share.png" class="logo-share"/>
</div>
</template>

<script>
export default {
  data(){
    return{
      logoFooter: ''
    }
  },
  mounted(){
    this.logoFooter = this.media.isDesktop ? require('@/assets/img/logo-vertical-big.png') : require('@/assets/img/logo-vertical.png')
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  },
}
</script>

<style lang="scss" scoped>
  .main-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer{
    background: #003359;
    color: #fff;
    padding: 80px 0px;
    @media (max-width: 768px) {
      padding: 70px 0 0;
      .row{
        > .col,
        > [class*="col-"]:last-child {
          .block{
            margin-bottom: 20px;
          }
        }
      }
    }

    .block{
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-bottom: 48px;
      }
      h4{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 24px;
        line-height: 140%;
        color: #FFFFFF;
        margin-bottom: 1rem;
        @media (max-width: 768px) {
          font-size: 18px;
          margin-bottom: 11px;
        }
      }

      a{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF !important;
        text-decoration: none;
        margin-bottom: 10px;
        &:hover{
          text-decoration: underline;
        }
        @media (max-width: 768px) {
          font-size: 14px;
          margin-bottom: 12px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }

      .socials{
        display: flex;
        margin-bottom: 2rem;
        .icon{
          margin-right: 8px;
        }
      }

      .logo-pay{
        width: 128px;
        height: 73px;
        img{
          width: 100%;
        }
        @media (max-width: 768px) {
          margin-bottom: 15px;
        }
      }

      .contact-info{
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .icon{
          margin-right: 10px;
          width: 30px;
          img{
            width: 100%;
          }
        }
        span{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 140%;
          color: #FFFFFF;

        }

        @media (max-width: 768px) {
          margin-bottom: 20px;
          .icon{
            width: 24px;
          }
          span{
            font-size: 14px;
          }

          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .content-logo{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .vertical-logo{
          width: 268px;
          height: 207px;
          @media (max-width: 768px) {
            width: 113px;
            height: 87px;

          }
          img{
            width: 100%;
          }
        }
      }
    }
  }

  .logo-share{
    
        width: 128px;
        height: 90px;
        display: none;
  }
  .sub-footer{
    background: #003359;
    color: #fff;
    padding-bottom: 15px;
    p{
      margin: 0;
      font-family: 'Open Sans', sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
    }
   @media (max-width: 768px){
     padding-top: 14px;
     padding-bottom: 14px;
   }
  }
</style>