<template>
  <div class="result-pay" >
    <div class="fail" v-if="!success && !loaderPay">
      <div class="animation-fail">
        <img src="@/assets/img/fail-animation.gif" alt="fail-animation" />
      </div>
      <div class="title-content">
        <h2>¡Ho no!</h2>
      </div>
      <div class="text-content">
        <p>Parece que algo salio mal al procesar tu pago. Por favor vuleve a intentarlo</p>
      </div>
      <button class="btn btn-back" @click="tryAgain">Volver a intentar</button>

    </div>
    <div class="success" v-if="success && !loaderPay" >
      <div class="content-success">
          <div class="header">
            <h3>¡Felicidades Juan! tu futuro esta por cambiar</h3>
            <p>Te enviamos un email a ju***o@gmail.com con las indicaciones para arrancar tu curso, el detalle de tu compra y una copia de este comprobante</p>
          </div>
          <div class="body">
            <div class="card-success">
              <div class="card-success-header">
                <h4>PAGO EXITOSO</h4>
                <h5>$ 1.421.021</h5>
                <span>Modallidad Full Access - Plan 12 meses</span>
              </div>
              <div class="item">
                <p>Número de la tarjeta</p>
                <span>XXXX-XXXX-XXXX-{{responseTransaction.card_detail.card_number}}</span>
              </div>
              <div class="item">
                <p>No de Cuotas</p>
                <span>{{responseTransaction.installments_number}}</span>
              </div>
              <div class="item dash">
                <p>Valor cuota</p>
                <span>$ {{responseTransaction.installments_amount}}</span> 
              </div>

              <div class="item">
                <p>Fecha de transación</p>
                <span>{{responseTransaction.transaction_date}}</span>  
              </div>
              <div class="item">
                <p>Hora de transación</p>
                <span>{{responseTransaction.transaction_date}}</span>
              </div>
              <div class="item">
                <p>Orden de compra</p>
                <span>{{responseTransaction.buy_order}}</span>
              </div>
              <div class="item">
                <p>Código de autorización</p>
                <span>{{responseTransaction.authorization_code}}</span>
              </div>
            </div>
            <router-link to="/" class="btn btn-volver">
              Volver a la pagina de Wall Street
            </router-link>
          </div>
      </div>
      <div class="animation-success"></div>
    </div>
    <div class="loader-pay" v-if="loaderPay">
      <p>Pasarela de pago</p>
      <div class="img-pay">
        <img src="@/assets/img/pay-desktop.png" alt="img-pay" v-if="media.isDesktop" />
        <img src="@/assets/img/pay-mobile.png" alt="img-pay" v-if="!media.isDesktop" />
      </div>
      <div class="amimation-pay"></div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      success: false,
      loaderPay: true,
      responseTransaction: {
        accounting_date: "1202",
        amount: 2250000,
        authorization_code: "000000",
        buy_order: "1638455055",
        card_detail: {card_number: "0568"},
        installments_amount: 750000,
        installments_number: 3,
        payment_type_code: "SI",
        response_code: -1,
        session_id: "sessionId-1638455055",
        status: "FAILED",
        transaction_date: "2021-12-02T14:21:07.739Z",
        vci: "TSY"
      }
    }
  },
  mounted(){
    this.commitOrder();
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    order() {
      return this.$store.state.order
    },
  },
  methods:{
    tryAgain(){
      this.loaderPay = true;
      this.commitOrder()
      /*setTimeout(() => {
        this.loaderPay = false;
      }, 3000);*/
    },
    async commitOrder(){
      try {
        const { data } = await this.Axios.post("/webpay/commit", {
          token: this.order.tokenWs,
        });
        this.responseTransaction = data
        if(data.response_code == 0 && data.status == 'AUTHORIZED'){
          this.success =  true
        } else {
          this.success =  false
        }
        this.loaderPay = false; 
      } catch (error) {
        console.log(error)
        this.loaderPay = false;
        alert('error de transbank') 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .result-pay{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .fail{
      width: 365px;
      padding: 15px;
      display: flex;
      flex-direction: column;

      .animation-fail{
        width: 100%;
        img{
          width: 100%;
        }
      }


      .title-content{
        h2{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 40px;
          line-height: 140%;
          text-align: center;
          color: #F12C3E;
          @media (max-width: 768px) {
            font-size: 30px;
            line-height: 120%
          }
        }
      }
      .text-content{
        margin-bottom: 20px;
        p{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 140%;
          text-align: center;
          color: #003359;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
      .btn-back{
        background: #003359;
        border-radius: 4px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
      }
    }

    .success{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      .animation-success{
        position: absolute;
        background-image: url('~@/assets/img/success-animation.gif');
        background-size: cover;
        width: 844px;
        height: 844px;
        top: -130px;
        z-index: -1;
        @media (max-width: 768px) {
          top: -160px;
          width: 100%;
          height: 440px;
          background-position: top center;
        }
      }

      .content-success{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .header{
          width: 735px;
          margin-bottom: 10px;
          @media (max-width: 768px) {
            width: 100%;
          }
          h3{
            font-family: 'Avenir LT Pro Black';
            font-style: normal;
            font-weight: 750;
            font-size: 22px;
            line-height: 140%;
            text-align: center;
            color: #003359;
            margin-bottom: 16px;
          }
          p{
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            color: #0082A9;
            @media (max-width: 768px) {
              text-align: left;
            }
          }
        }

        .body{
          width: 335px;
          @media (max-width: 768px) {
            width: 100%;
          }
          .card-success{
            background: #FDFDFD;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .card-success-header{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              padding: 10px 0 20px;
              h4{
                font-family: 'Avenir LT Pro Light';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-transform: uppercase;
                color: #003359;
                margin: 0;
              }
              h5{
                font-family: 'Avenir LT Pro Black';
                font-style: normal;
                font-weight: 750;
                font-size: 35.5862px;
                line-height: 120%;
                color: #003359;
                margin: 0;
              }
              span{
                font-family: 'Avenir LT Pro Black';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                color: #003359;
              }
            }
            .item{
              padding: 5px 0;
              p{
                margin: 0;
                font-family: 'Avenir LT Pro Light';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                color: #808080;
              }
              span{
                font-family: 'Avenir LT Pro Light';
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 140%;
                color: #003359;
              }
            }
            .dash{
              border-bottom: 0.5px dashed #003359;
            }
          }
          .btn-volver{
            background: #003359;
            border-radius: 4px;
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 140%;
            color: #FFFFFF;
          }
        }
      }
    }

    .loader-pay{
      padding: 30px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      p{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14.9415px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 14.9415px;
          line-height: 20px;
        }
      }

      .img-pay{
        width: 315px;
        @media (max-width: 768px) {
          width: 252px;
        }
        img{
          width: 100%;
        }
      }

      .amimation-pay{
        position: absolute;
        background-image: url('~@/assets/img/loading.gif');
        background-size: cover;
        width: 447px;
        height: 447px;
        z-index: -1;
        top: 0px;
        @media (max-width: 768px) {
          width: 100%;
          background-position: top center;
        }
      }
    }
  }
</style>