<template>
  <div class="container">
    <b-modal size="364" v-model="viewModal" hide-footer hide-header class="modal-plan" no-close-on-esc no-close-on-backdrop>
      <div class="icon-close">
        <img src="@/assets/img/close-black.png" alt="close-black"
          @click="closeModal"
        />
      </div>
      <div class="modal-contenido" v-if="selectedProgram" >
        <h3>{{ title }}</h3>
        <div class="item-program">
          <div class="header-item">
            <div class="small-text">
              <span class="firts">Modalidad</span> <span class="second">{{getModality()}}</span>
            </div>
            <h4>Plan {{getOnlyName(selectedProgram.name)}}</h4>
          </div>
          <div class="body-item">
            <p>Incluye:</p>
            <div class="featured" v-for="(detail, k) in selectedProgram.Details" :key="k" >
              <div class="check">
                <img src="@/assets/img/check_blue.svg" alt="check" />
              </div>
              <p>
                {{ detail.ProgramDetail.value }} {{ detail.name }}
              </p>
            </div>
          </div>
          <div class="box-item">
            <CustomSelect :locations="locationFormat" @selected="choseLocation" v-if="!location" />
            <div class="location-info" v-if="location" >
              <div class="flex change-location">
                <span>Sede:</span>  <a href="#" @click.prevent="changeLocation" v-if="locationFormat.length > 1">Cambiar sede</a>
              </div>
              <div class="location-name">
                <img src="@/assets/svg/location_pin.svg" alt="location_pin" />
                <p>{{ location.name  }}</p>
              </div>
            </div>
          </div>
          <div class="footer-item">
            <div class="price-list" v-if="selectedProgram.pricelist != selectedProgram.price">
              <span class="price-list-text">Precio Lista</span>
              <span class="price-list-value">{{selectedProgram.pricelist | moneyFormatter}}</span>
            </div>
            <div class="price-list" v-else>
              <span class="price-list-text">&nbsp;</span>
              <span class="price-list-value">&nbsp;</span>
            </div>

            <div class="price-promo" v-if="selectedProgram.pricelist != selectedProgram.price">
              <span class="price-promo-text">Precio Promocional</span>
              <span class="price-promo-value">{{selectedProgram.price | moneyFormatter}}*</span>
            </div>
            <div class="price-promo" v-else>
              <span class="price-promo-text">Precio Lista</span>
              <span class="price-promo-value">{{selectedProgram.pricelist | moneyFormatter}}*</span>
            </div>
            <!--div class="price-list">
              <span class="price-list-text">Precio Lista</span>
              <span class="price-list-value">{{ selectedProgram.pricelist | moneyFormatter}}</span>
            </div>
            <div class="price-promo">
              <span class="price-promo-text">Precio Promocional</span>
              <span class="price-promo-value">{{ selectedProgram.price | moneyFormatter}}*</span>
            </div-->
            <span class="subtitle">*Matrícula incluye descuento de 50%  <del>$120.000</del> $60.000</span>
            <span class="subtitle">*Promoción válida hasta Marzo año 2023</span>
            <span class="subtitle">* Paga hasta en 12 cuotas sin interés</span>
          </div>
        </div>
        <div class="action">
            <button class="btn" :disabled="!location" @click="nextBuy" >
            Continuar con la compra
          </button> 
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CustomSelect from "@/components/commons/CustomSelect";
export default {
  components:{
    CustomSelect
  },
  props:{
    title: {
      type: String,
      default: 'Selecciona una Sede'
    }
  },
  data(){
    return {
      locationFormat: [],
      viewModal: true,
      location: null
    }
  },
  mounted(){
    const program = this.$store.state.selectedProgram;
    const locationList = program.Locations.sort((l1, l2) => (l1.regionId < l2.regionId) ? -1 : (l1.regionId > l2.regionId) ? 1 : 0);
    const regions = locationList.map(row => row.Region.name)
    const regionsOnly = [...new Set(regions)]
    let arrayFinal = []
    regionsOnly.forEach(region => {
      const childrens =  locationList.filter(loc => loc.Region.name === region);
      arrayFinal.push({ region: region, locations: childrens})
    })
    this.locationFormat = arrayFinal;
    if(this.locationFormat.length == 1){
      this.location = arrayFinal[0].locations[0]
    }
  },
  computed: {
    selectedProgram() {
      return this.$store.state.selectedProgram;
    }
  },
  methods:{
    getModality(){
      switch (this.$route.params.slug) {
        case 'global-online-center':
          return 'GLOBAL OC';
        case '100-online':
          return '100% ONLINE';
        case '100-in-center':
          return 'IN CENTER';
        default:
          return 'FULL ACCESS';
      }
    },
    getOnlyName(name){
      switch (this.$route.params.slug) {
        case 'global-online-center':
          return name.split('Global')[0];
        case '100-online':
          return name.split('Online')[0];
        case '100-in-center':
          return name.split('Center')[0];
        default:
          return name.split('Full Access')[0];
      }
    },
    choseLocation(event){
      this.location = event
    },
    async nextBuy(){
      if(this.location){
        await this.$store.dispatch("saveSelectedLocation", this.location)
      }
      this.$router.push({ name: 'Checkout' });
    },
    closeModal(){
      this.viewModal = false;
      this.$emit('closeModal')
    },
    async changeLocation(){
      this.location = null
      await this.$store.dispatch("saveSelectedLocation", this.location)
    }
  },
}
</script>

<style lang="scss" scoped>

  .icon-close{
    display: flex;
    justify-content: flex-end;
    img{
      cursor: pointer;
    }
  }
 
  .modal .modal-dialog.modal-364 {
   width: 364px !important;
  }
  
  .modal-contenido{
    h3{
      font-family: 'Avenir LT Pro Black';
      font-style: normal;
      font-weight: 750;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: #003359;
      margin-bottom: 15px;
    }
  }
  
  .item-program{
    background: #F5F5F5;
    border-radius: 4px;
    
    .header-item{
      background: #0082A9;
      padding: 15px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      flex-direction: column;
      .small-text{
        width: 100%;
      }
      span{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
      }
      .firts{
        color: #A6D3E1;
      }
      .second{
        color: #fff;
      }
      h4{
        font-family: 'Avenir LT Pro Black';
        font-style: normal;
        font-weight: 750;
        font-size: 24px;
        line-height: 120%;
        color: #FFFFFF;
        margin: 0;
      }
    }
    .body-item{
      padding: 15px;
      p{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #404040;
        margin-bottom: .8rem;
      }
      .featured{
        display: flex;
        .check{
          width: 16px;
          margin-right: 10px;
          img{
            width: 100%;
          }
        }
        p{
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 140%;
          color: #404040;
          margin-bottom: .8rem;
        }
      }
    } 
    .location-info{
      width: 100%;
      display: flex;
      flex-direction: column;
      span{
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #404040;
        margin-bottom: 8px;
      }
      .location-name{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
          margin-right: 10px;
        }
        p{
          margin: 0;
          font-family: 'Avenir LT Pro Light';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          color: #404040;
        }
      }
    }
    .footer-item{
      padding: 15px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 8px 8px;
      display: flex;
      flex-direction: column;
      .price-list{
        display: flex;
        justify-content: space-between;
        .price-list-text,.price-list-value{
          font-family:'Avenir LT Pro Light';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          display: flex;
          align-items: center;
          color: #0082A9;
        }
        .price-list-value{
          text-decoration-line: line-through;
        }
      }
      .price-promo{
        display: flex;
        justify-content: space-between;
        .price-promo-text,.price-promo-value{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 18px;
          line-height: 32px;
          color: #003359;
          @media (max-width: 321px) {
            font-size: 15px;
          }
        }
      }
    } 
    .subtitle{
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #003359;
    }
    .box-item{
      padding: 15px;
      border-top: 0.5px solid #A6B8C5;
    }
  }

  .action{
    width: 100%;
    padding: 15px;
    button{
      width: 100%;
      background: #003359;
      border-radius: 4px;
      color: #fff;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      cursor: pointer;
    }
  }
  .change-location{
    justify-content: space-between;
    align-items: flex-start;
  }
</style>

<style lang="scss">
  .modal .modal-dialog.modal-364 {
    width: 364px !important;
    @media (max-width:768px) {
      width: auto !important;
    }
  }
</style>