<template>
  <layout-default>
    <div class="home wse-page">
<!-- 
      <div class="mantención">
      <h1>SITIO EN MANTENCIÓN</h1>
      <img src="@/assets/img/logo-desktop.svg" alt="logo-wst" />
      </div>  -->

      <BannerDesktop v-if="media.isDesktop" :info-general="infoGeneral" />
      <BannerMobile v-if="!media.isDesktop" :info-general="infoGeneral" />
      <div class="brand-red">
        <div class="container">
          <p class="brand-text">
            Cambia tu futuro aprendiendo inglés. Encuentra un curso <b>a tu medida y vive la nueva forma de aprender</b>
          </p>
        </div>
      </div>
      <PublicyDesktop v-if="media.isDesktop" />
      <PublicyMobile v-if="!media.isDesktop" />
      <ModalityDesktop v-if="media.isDesktop" @selectedPlan="selectedPlan" />
      <ModalityMobile v-if="!media.isDesktop" @selectedPlan="selectedPlan" />
      <Learnings />
      <FloatDivDesktop  v-if="media.isDesktop" />
      <FloatDivMobile v-if="!media.isDesktop" />
      <OpinionsCarousel />
      <ModalClient v-if="next" @continuar="nextModal" @closeModal="next=false" />
    </div> 
  </layout-default>
</template>

<script>
import axios from 'axios';

import LayoutDefault from "@/layouts/Default";
import BannerMobile from "@/components/home/BannerMobile";
import BannerDesktop from "@/components/home/BannerDesktop";
import PublicyMobile from "@/components/home/PublicyMobile";
import PublicyDesktop from "@/components/home/PublicyDesktop";
import ModalityMobile from "@/components/home/ModalityMobile";
import ModalityDesktop from "@/components/home/ModalityDesktop";
import Learnings from "@/components/home/Learnings";
import OpinionsCarousel from "@/components/home/OpinionsCarousel";
import FloatDivDesktop from "@/components/home/FloatDivDesktop";
import FloatDivMobile from "@/components/home/FloatDivMobile";
import ModalClient from "@/components/client/ModalClient";

export default {
  components: {
    BannerMobile,
    BannerDesktop,
    PublicyMobile,
    PublicyDesktop,    
    ModalityMobile,
    ModalityDesktop,
   LayoutDefault,
    Learnings,
    OpinionsCarousel,
    FloatDivDesktop,
    FloatDivMobile,
    ModalClient
  },
  metaInfo: {
    title: 'Wall Street English'
  },
  data(){
    return {
      modalShow: true,
      next: false,
      slugGo: '',
      infoGeneral: null
    }
  },
  mounted(){
    localStorage.clear();
    sessionStorage.removeItem('step');
    this.getData()
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  },
  methods:{
    nextModal(){
      this.$router.push(`modality/${this.slugGo}`);
      this.next = false
    },
    selectedPlan(event){
      this.slugGo = event
      if(sessionStorage.getItem('lead')){
        this.next = false
        setTimeout(() => {
          this.$router.push(`modality/${event}`);
        }, 500);
      } else {
        this.next = true
      } 
    },
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/dashadmin/infogeneral`)
        if(data.length > 0){
          this.infoGeneral = data[0]
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  
  h1{
    font-family: 'Avenir LT Pro Black';
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: #003359;
      opacity: 0.8;
      text-align: center;
      margin-bottom: 25px;
  }
  img{
    height:120%;
    width: 60%;
  }

  .mantención{
    padding-top: 50px;
    text-align: center;
 
  }
  
  .home{
    width: 100%;
  }
  .brand-red {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Avenir LT Pro Light';
    font-style: normal;
    font-weight: normal;
    text-align: center;
    background-color: #f13244;
    padding: 25px 0px;
  
    .brand-text{
      color: #fff;
      font-family: 'Avenir LT Pro Light';
      font-size: 28px;
      line-height: 140%;
      margin: 0;

    }

    @media (max-width: 768px) {
      padding: 36px 0;
      .brand-text{
        font-size: 18px;
        text-align: center;
        line-height: 25px;
      }
    }
  }
</style>