<template>
  <div class="modality-content modality-page">
    <div class="desktop-modality" v-if="media.isDesktop" >
      <div class="container">
        <div class="content-modality">
          <h3 class="title">Revisa otras modalidades</h3>
          <div class="row">
            <div class="col-4 col-md-4" v-for="(modality, index) in modalitys" :key="index" >
              <div class="card item-modality">
                <div class="card-body item-modality-content">
                  <div class="modaly-header">
                    <div class="modaly-avatar">
                      <img :src="require(`@/assets/img/${modality.image}`)" :alt="modality.title" />
                    </div>
                    <div class="modaly-title-content">
                      <h3 class="modaly-title">{{ modality.title }}</h3>
                    </div>
                  </div>
                  <div class="modaly-content">
                    <div class="modaly-content-data">
                      <div class="text">
                        <p>{{  modality.featureds[0].text }}</p>
                      </div>
                    </div>
                  </div>
                  <a :href="modality.slug" class="button_slide" :class="modality.colorName"
                    :style="{ color: modality.color, borderColor: modality.color }"
                  >
                    Ver planes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-modality" v-if="!media.isDesktop">
      <div class="container no-padding-mobile">
        <h3 class="title">Revisa otras modalidades</h3>
        <div class="carousel-mobile">
          <carousel
            :per-page="1"
            :navigationEnabled="false"
            :paginationEnabled="false"
          >
            <slide v-for="(modality, index) in modalitys" :key="index">
              <div class="card item-modality">
                <div class="card-body item-modality-content">
                  <div class="modaly-header">
                    <div class="modaly-avatar">
                      <img :src="require(`@/assets/img/${modality.image}`)" :alt="modality.title" />
                    </div>
                    <div class="modaly-title-content">
                      <h3 class="modaly-title">{{ modality.title }}</h3>
                    </div>
                  </div>
                  <div class="modaly-content">
                    <div class="modaly-content-data">
                      <div class="text">
                        <p>{{  modality.featureds[0].text }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="button_slide" :class="modality.colorName"
                    :style="{ color: modality.color, borderColor: modality.color }"
                  >
                    Ver planes
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      modalitys: []
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}modalityData.json`)
        let array =  []; 
        array = data;
        this.modalitys = array.filter(row => row.slug != this.$route.params.slug)
      } catch (error) {
        console.log(error)
      }
    },
    appllyStyles(color){
      return {
        color: color, 
        borderColor: color
      }
    }
  },
  computed: {
    media() {
      return {
        'isPhone': this.$screen.xs,
        'isTablet': this.$screen.md,
        'isDesktop': this.$screen.lg,
        'canTouch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    }
  },
}
</script>

<style lang="scss" >
  .modality-page{
    .no-padding-mobile{
      @media (max-width:768px) {
        padding: 0!important;
      }
    }
    .VueCarousel-wrapper{
      padding: 0 40px;
    }
    .VueCarousel-slide{
      .item-modality{
        margin: 0 7px;

      }
    }

    .col-4{
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
    .card-body{
      padding: 1.25rem 0.9rem;
    }

    .modality-content{
      width: 100%;
    }

    .desktop-modality{
      background-image: url('~@/assets/img/Dibujo-fondo.png');
      background-size: cover;
      width: 100%;
      height: 560px;
      background-repeat: no-repeat;
      background-position: 15% 15%;

      .content-modality{
        width: 100%;
        padding-top: 100px;
      }
    }
    .mobile-modality{
      padding: 50px 0px;
    }


    .carousel-mobile{
      width: 100%;
    }

    .title{
      font-family: 'Avenir LT Pro Black';
      color: #003359;
      font-size: 35px;
      line-height: 140%;
      font-weight: bold;
      text-align: center;
      margin-bottom: 60px;
      @media (max-width: 768px) {
        font-size: 24px !important;
        line-height: 120% !important;
      }
    }

    .item-modality{
      height: 266px;
      margin-bottom: 15px;
      background: #FFFFFF;
      border: 1px solid #ECECEC !important;
      box-sizing: border-box !important;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
      border-radius: 8px !important;

    }

    .item-modality-content{
      display: flex;
      flex-direction: column;
    }

    .modaly-header{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .modaly-avatar{
        margin-right: 10px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .modaly-title-content{
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .modaly-title{
          font-family: 'Avenir LT Pro Black';
          font-style: normal;
          font-weight: 750;
          font-size: 20px;
          line-height: 28px;
          text-transform: uppercase;
          color: #404040;
          margin-bottom: 0;
        }
      }
    }

    .modaly-content{
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      .modaly-content-data {
        display: flex;
        .icon{
          width: 24px;
          margin-right: 12px;
        }
        .text{
          p{
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 140%;
            display: flex;
            align-items: flex-end;
            color: #404040;
            margin: 0;
          }
        }
      }
    }

    .button_slide {
      border: 1px solid;
      border-radius: 4px;
      padding: auto;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Avenir LT Pro Light';
      font-style: normal;
      font-weight: 750;
      font-size: 20px;
      line-height: 140%;
      cursor: pointer;
      -webkit-transition: ease-out 0.4s;
      -moz-transition: ease-out 0.4s;
      transition: ease-out 0.4s;
      text-decoration: none;
    }

    .slide_right_azul{
      background: #0082A9;
      background: linear-gradient(to left, transparent 50%, #0082A9 50%) right;
      background-size: 200%;
      &:hover{
        color: #fff !important;
        background-position: left;
      }
    }
    .slide_right_rojo{
      background: #F5616E;
      background: linear-gradient(to left, transparent 50%, #F5616E 50%) right;
      background-size: 200%;
      &:hover{
        color: #fff !important;
        background-position: left;
      }
    }
    .slide_right_turquesa{
      background: #64CFE9;
      background: linear-gradient(to left, transparent 50%, #64CFE9 50%) right;
      background-size: 200%;
      &:hover{
        color: #fff !important;
        background-position: left;
      }
    }
    .slide_right_salmon{
      background: #FF8E7E;
      background: linear-gradient(to left, transparent 50%, #FF8E7E 50%) right;
      background-size: 200%;
      &:hover{
        color: #fff !important;
        background-position: left;
      }
    }

  }


</style>