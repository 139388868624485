<template>
  <div class="main-header">

    <div class="desktop">
      <div class="container">
        <div class="content-menu">
          <div class="logo">
            <router-link class="navigation-link" to="/">
              <img src="@/assets/img/logo-desktop.svg" alt="logo-wst" />
            </router-link>
          </div>
          <div class="navigation" v-if="currentUrl != '/checkout' || currentUrl != '/payment-result'">
            <div class="navigation-item" :class="{active: currentRoute == '/'}">
              <router-link class="navigation-link " :class="{'font-normal': openModality, 'navigation-link-border': !openModality, 'in-modality': url}" to="/">Inicio</router-link>
            </div>
            <div class="navigation-item dropdown " :class="{active: openModality}" >
              <a class="navigation-link font-normal dropbtn" 
                href="#" @click.prevent="openCloseModality" 
                :class="{'router-link-active font-bold navigation-link-border-modality': openModality || url }"
              >
                Modalidades
                <img src="@/assets/img/keyboard_arrow_down.svg" alt="keyboard_arrow_down" v-if="!openModality" class="arrow" />
                <img src="@/assets/img/keyboard_arrow_up.svg" alt="keyboard_arrow_up" v-if="openModality" class="arrow" />
              </a>
              <div class="dropdown-content" v-if="openModality">
                <!--a class="dropdown-link" href="/modality/global-online-center">Global Online Center</a-->
                <a class="dropdown-link" href="/modality/100-online">100% Online</a>
                <a class="dropdown-link" href="/modality/100-in-center">100% In Center</a>
                <a class="dropdown-link" href="/modality/full-access">Full Access</a>
                <a class="dropdown-link" href="/modality/teens-online">Teens</a>
              </div>
            </div>
            <!--div class="navigation-item" :class="{active: currentRoute == '/test'}" >
              <router-link class="navigation-link" to="/test">Conoce tu nivel de Inglés</router-link>
            </div-->
            <div class="navigation-item">
              <a class="navigation-link-last" href="https://www.wallstreetenglish.cl/" target="_blank">
                Ir a Wall Street English
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mobile">
      <div class="main-header-image">
        <router-link class="navigation-link" to="/">
          <img src="@/assets/img/logo-horizontal.svg" alt="logo-wst" />
        </router-link>
      </div>
      <div class="hamburger-content">
        <div class="hamburger-menu">
          <div class="hamburguer-btn" @click="openCloseMenuMobile">
            <!--div class="line"></div>
            <div class="line"></div>
            <div class="line"></div-->
            <img src="@/assets/img/menu.svg" alt="menu"  v-if="!openMenuMobile"/>
            <img src="@/assets/img/close.svg" alt="menu"  v-if="openMenuMobile"/>
          </div>
        </div>
      </div>
      <div class="content-menu-mobile" v-if="openMenuMobile" >
        <div class="navigation-mobile" v-if="currentUrl != '/checkout'">
          <div class="mobile-navigation-item">
            <router-link class="navigation-link " :class="{'font-normal': openModality, 'navigation-link-border': !openModality}" to="/">Inicio</router-link>
          </div>
          <div class="mobile-navigation-item" :class="{active: openModality}" >
            <a class="mobile-navigation-link" :class="{'font-normal': openModality, 'navigation-link-border': !openModality}"
              href="#" @click.prevent="openCloseModalityMobile"
            >
              Modalidades
              <img src="@/assets/img/keyboard_arrow_down_white.svg" alt="keyboard_arrow_down_white" v-if="!openModalityMobile" class="arrow" />
              <img src="@/assets/img/keyboard_arrow_up_white.svg" alt="keyboard_arrow_up_white" v-if="openModalityMobile" class="arrow" />
            </a>
          </div>
          <div class="mobile-dropdown-content" v-if="openModalityMobile">
            <!--a class="mobile-dropdown-link" href="/modality/global-online-center">Global Online Center</a-->
            <a class="mobile-dropdown-link" href="/modality/100-online">100% Online</a>
            <a class="mobile-dropdown-link" href="/modality/100-in-center">100% In Center</a>
            <a class="mobile-dropdown-link" href="/modality/full-access">Full Access</a>
          </div>
          <div class="mobile-navigation-item">
            <router-link class="mobile-navigation-link" to="/test">Conoce tu nivel de Inglés</router-link>
          </div>
          <div class="mobile-navigation-item">
            <a class="mobile-navigation-link" href="https://www.wallstreetenglish.cl/" target="_blank">
              Ir a Wall Street English
            </a>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  props:{
    currentUrl: {
      type: String, 
      default: ''
    },
  },
  data(){
    return{
      openModality: false,
      openMenuMobile: false,
      openModalityMobile: false,
      url: window.location.pathname.split('/')[1] === 'modality' ? true : false,
      home: this.openModality && this.url ? true : false

    }
  },
  computed: {
    currentRoute() {
      return this.$route.fullPath
    }
  },
  methods:{
    openCloseModality(){
      this.openModality = !this.openModality
    },
    openCloseMenuMobile(){
      this.openMenuMobile = !this.openMenuMobile
    },
    openCloseModalityMobile(){
      this.openModalityMobile = !this.openModalityMobile
    },
  }
}
</script>

<style lang="scss" scoped>
 
 
 
 .main-header{
    position: fixed;
    width: 100%;
    display: flex;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    z-index: 100;
    @media (max-width: 768px) {
      height: 60px;
      padding: 0 16px;
    }




    .desktop{
      width: 100%;
      @media (max-width: 768px) {
        display: none;
      }
      @media (min-width: 768px) {
        display: flex;
      }
      .content-menu{
        width: 100%;
        height: 100%;
        display: flex;
        .logo{
          width: 203px;
          height: 100%;
          img{
            width: 100%;
          }
        }
        .navigation{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          flex: 1;
          .navigation-item{
            height: 100%;
            position:relative;
            margin-left: 30px;
            .navigation-link{
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: 'Avenir LT Pro Black';

              font-size: 18px;
              line-height: 140%;
              color: #003359;
              text-decoration: none;
            }
            .navigation-link-last{
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: 'Avenir LT Pro Black';
              font-style: normal;
              font-weight: 750;
              font-size: 18px;
              line-height: 140%;
              color: #0082A9;
              text-decoration: none;
            }
          }
        }
      }

      .arrow{
        margin-left: 5px;
      }
      .dropdown-content {
        display: block;
        position: absolute;
        background-color: #fff;
        min-width: 239px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 2;
      }
      .dropdown-content .dropdown-link {
        float: none;
        padding: 10px 20px;
        text-decoration: none;
        display: block;
        text-align: left;
        font-family: 'Avenir LT Pro Light';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #757575;
        &:hover{
          cursor: pointer;
        }
      }
      .router-link-active{
        font-family: 'Avenir LT Pro Black' ;
        font-weight: 750;
      }
    }

    .mobile{
      width: 100%;
      @media (max-width: 768px) {
        display: flex;
      }
      @media (min-width: 768px) {
        display: none;
      }
      .main-header-image{
        display: flex;
        flex: 1;
        width: 172px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .hamburger-content{
        height: 100%;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;

        .hamburger-menu{
          width: 60px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .hamburguer-btn{
            width: 24px;
            img{
              width: 100%;
            }

           /*width: 24px;
            display: flex;
            flex-direction: column;
            .line:first-child, .line:nth-child(2) {
              margin-bottom: 3px;
            }
            .line {
              width: 18px;
              height: 2px;
              background: #003359;
            }*/
          }
        }
      }
    }

    .content-menu-mobile{
      position: absolute;
      right: 0;
      left: 0;
      top: 60px;
      background-color: #003359;
      z-index: 100;
      min-height: calc(100vh - 60px);

      .navigation-mobile{
        height: 100%;
        display: flex;
        flex-direction: column;
        .mobile-navigation-item{
          padding: 15px 0px;
          border-top: 1px solid #64CFE9;
          &:first-child{
            border-top: 0px solid;
          }
          .mobile-navigation-link{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            font-family: 'Avenir LT Pro Black';
            font-style: normal;
            font-weight: 750;
            font-size: 18px;
            line-height: 140%;
            text-align: center;
            color: #FFFFFF;
            text-decoration: none;
          }
        }
        .mobile-dropdown-content{
          display: flex;
          flex-direction: column;
          background: #406683;

          .mobile-dropdown-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 20px 20px 20px 40px;
            font-family: 'Avenir LT Pro Light';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 140%;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  
</style>

<style>
  .active .navigation-link-border::after{
    content: "";
    display: block;
    position: absolute;
    top: 85%;
    border-top: 2px solid #003359;
    width: 100%;
    font-family: 'Avenir LT Pro Black' !important;
  }
  .active .navigation-link-border-modality::after{
    content: "";
    display: block;
    position: absolute;
    top: 85%;
    border-top: 2px solid #003359;
    width: 100%;
  }



  .font-normal{
  font-weight: 400 !important;
  font-family:  'Avenir LT Pro Light' !important;
 }
 .font-bold{
  font-weight: 800 !important;
  font-family:  'Avenir LT Pro Black' !important;
 }

 .font-normal ~ .active .navigation-link::after{
  border:none;
 }
 .in-modality {
  font-weight: 400 !important;
  font-family:  'Avenir LT Pro Light' !important;
 }

</style>